@import 'src/assets/styles/globalStyles';

.notes-section-wrapper {
  padding: 0 16px;
  @media (min-width: $md) {
    padding: 0 24px;
  }
  @media (min-width: 1024px) {
    padding: 0;
  }

  .add-note-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;
    @media (min-width: $md) {
      margin-top: 16px;
    }
    @media (min-width: 1280px) {
      margin-top: 0;
    }

  }

  .notes-wrapper {
    margin-top: 16px;
    height: 100%;
    overflow: auto;
    max-height: 65vh;
  }

  .note-card {
    width: 100%;
    height: auto;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .icon-and-info {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .note-title {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $tableHeadText;
  }

  .note-title-and-info {
    margin-left: 10px;
  }

  .date-and-creator {
    display: flex;
    margin-top: 10px;


    @media (max-width: $md) {
      flex-direction: column;
    }

    .creator-name {
      @media (min-width: 320px) {
        margin-top: 10px;
      }
      @media (min-width: $md) {
        margin-left: 20px;
        margin-top: 0;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $gunmetal500;
    }

    span {
      font-weight: 600 !important;
    }
  }

  .popper-button {
    background: transparent;
    border: none;
  }

}


.add-note-wrapper {
  max-width: 343px;
  width: auto;
  height: auto;

  @media (min-width: 768px) {
    max-width: 514px;
  }
}