@import 'src/assets/styles/globalStyles';

// User Card.

.user-card {
    padding: 16px;
    background: #ffffff;
    border-radius: 4px;

    .card-header {
        display: flex;
        justify-content: flex-end;
        .header-btn {
            position: relative;
            right: 12px;
            top: 14px;
        }
        .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: $main;
        }
        .popper-wrapper {
            transform: rotate(90deg);
            bottom: 0;
        }
    }
    .card-content {
        display: flex;
        flex-direction: column;
        .text-green {
            color: $main;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
        }
        .text-email {
            color: $gunmetal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            width: 100%;
        }
        .text-unic {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $gunmetal;
        }
        .text-resault {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $gunmetal;
        }
        .text-active,
        .text-available {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $main;
        }
        .text-orange,
        .text-pending,
        .text-maintence {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $orange;
        }
        .text-transit,
        .text-hold {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $blue;
        }
        .text-inactive {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $gunmetal800;
        }
        .text-not {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $blue;
        }
        .text-space {
            margin-left: 2px;
            margin-right: 2px;
        }
    }
    .transaction-card {
        .card-load {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;
        }
        .card-type {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;
            .desktop-box {
                display: none;
            }
        }
        .card-descrption {
            display: flex;
            padding: 8px 0;
        }
        .card-footer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-top: 12px;
        }
    }
    .text-bold {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $gunmetal800;
        @media (max-width: $md) {
            font-size: 14px;
        }
    }
    .text-info {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $gunmetal;
        margin-left: 5px;
    }
    .space-top {
        margin-top: 4px;
    }
}

.cost-card {
    padding: 12px 16px;
    border-radius: 8px;
    min-height: 92px;
    height: 102px;
    margin-bottom: 16px;
    box-shadow: none;
    transition: none;
    background-color: inherit;
    .cost-box {
        display: flex;
        flex-direction: column;
        .img-box {
            margin-right: 8px;
        }
        .title-box {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $gunmetal500;
        }
        .text-cents {
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            color: $darkText;
        }
        .text-per {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: $lightGunmetal;
            margin-left: 5px;
        }

        .text-bold {
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            margin-left: 5px;
        }
    }
    .cost-body {
        margin-top: 18px;
    }
    .cost-body,
    .cost-header {
        display: flex;
        align-items: center;
    }
}

.dollar-symbol {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #324557;
}

.active-card {
    background: $lightMain;
}

.light-card {
    background: $greyWhite;
    border: 1px solid #e7effb;
}

.error-card {
    background: #fff4f4;
    border: 1px solid #fce2df;
    border-radius: 4px;
    padding: 10px 16px;
    display: flex;
    .card-img {
        margin-right: 10px;
    }
    .card-box {
        display: flex;

        .error-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #d03325;
        }
        .error-subtitle {
            margin-left: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #51566d;
        }
    }
}

@media (max-width: 599px) {
    .user-card {
        .transaction-card {
            .mobile-text {
                display: none;
            }
            .card-type {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .desktop-box {
                    display: block;
                }
            }
            .card-descrption {
                display: block;
            }

            .card-footer {
                justify-content: initial;
                .action-box {
                    width: 100%;
                    .action-danger,
                    .action-gunmetal {
                        width: 100%;
                        text-align: center;
                        padding: 8px 0;
                    }
                }
            }
        }
    }
}
