@import 'src/assets/styles/globalStyles';

//NEW STYLES TRY DO SOMETHING LIKE THIS

//GLOBAL BUTTONS

.save-green-btn {
    width: 174px;
    height: 48px;
    background: #0e9594;
    border-radius: 4px;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
}

.cancel-gray-btn {
    width: 174px;
    height: 48px;
    background: #f5f7f9;
    border-radius: 4px;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $tableHeadText;
}

//End

// Delete Modal

.delete-form {
    @media (min-width: $xs) {
        width: 320px;
    }
    @media (min-width: $md) {
        width: 439px;
    }

    .delete-modal-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-align: center;

        .want-delete {
            color: $gunmetal800;
        }

        .delete-title {
            color: $red;
        }
    }

    .delete-subtitle {
        margin-top: 24px;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: $darkText;
    }

    .delete-modal-buttons-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 25px;
    }

    .delete-button {
        width: 100%;
        height: 48px;
        background: $red;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        border: none;
        outline: none;
    }

    .cancel-button {
        width: 100%;
        height: 48px;
        background: $greyWhite;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $tableHeadText;
        margin-right: 16px;
        border: none;
        outline: none;
    }
}

// End

// Activate Inactivate Modal

.inactivate-form {
    text-align: center;
    @media (min-width: $xs) {
        width: 320px;
    }
    @media (min-width: $md) {
        width: 426px;
    }

    .inactivate-title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: $gunmetal800;
    }

    .green-title {
        color: $main !important;
    }

    .inactivate-description {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: $darkText;
        margin: 24px 0;
    }
}

//End

//END

.modal-wrapper {
    background-color: $white;
    z-index: 1000;
    border-radius: 16px;
    padding: 32px;
    max-height: 90vh;
    overflow: auto;

    @media (min-width: $xs) {
        padding: 16px;
    }
    @media (min-width: $md) {
        padding: 32px;
    }
    .close-button {
        border: none;
        background-color: transparent;
        @media (min-width: $xs) {
            margin-top: -10px;
            margin-right: -10px;
        }
        @media (min-width: $md) {
            margin-top: -25px;
            margin-right: -25px;
        }
    }
    .modal-title {
        font-weight: 700;
        font-size: 32px;
        color: $gunmetal800;
        margin-bottom: 16px;
        @media (max-width: $md) {
            font-size: 24px;
        }
    }
    .title-box {
        margin-top: 24px;
        margin-bottom: 24px;
        text-align: center;
        color: $darkGunmetal;
    }
    .input-border {
        border: 1px solid $gunmetal500;
    }
    .error-border {
        border: 2px solid $red;
        border-radius: 4px;
    }
    .title-green {
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        color: $main;
        margin-right: 5px;
    }
    .title-red {
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        color: $red;
    }
    .title-black {
        color: $gunmetal800;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
    }
    .footer-box {
        // display: grid;
        // grid-template-columns: auto auto;
        // grid-gap: 16px;
        .green-btn {
            text-transform: none;
            background: $main;
            :hover {
                background-color: $main;
                background: $main;
            }
            .button-text {
                color: $lightMain;
            }
        }
        .default-btn {
            text-transform: none;
            background: $greyWhite;
            :hover {
                background-color: $greyWhite;
                background: $greyWhite;
            }
            .button-text {
                color: $tableHeadText;
            }
        }
        .red-btn {
            text-transform: none;
            background: $red;
            :hover {
                background-color: $red;
                background: $red;
            }
            .button-text {
                color: $lightMain;
            }
        }
        .submit-box {
            margin-top: 8px;
            margin-bottom: 8px;
            .add-modal-button {
                padding: 12px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: none;
                .button-text {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    .group-input {
        padding: 0;
        .name-control {
            color: $lightGunmetal;
            border: 1px solid $lightGunmetal;
            background-color: $white;
            margin-bottom: 16px;
            padding: 12px;
        }
        .email-control {
            color: $lightGunmetal;
            border: 1px solid $lightGunmetal;
            background-color: $white;
            margin-bottom: 16px;
            padding: 12px;
        }
        .form-control {
            display: block;
            width: 100%;
            padding: 12px 16px 12px 16px;
            line-height: 1.5;
            color: $gunmetal500;
            background-color: $white;
            background-clip: padding-box;
            border: 1px solid $gunmetal500;
            border-radius: 4px;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            :focus {
                border-color: $gunmetal500;
            }
        }
    }
}

.modal-wrapper:focus-visible {
    outline: none !important;
    border: none;
}

// Driver Past Modal

.past-modal {
    height: 300px;
    width: 900px;
    overflow-y: scroll;
    .table-row {
        background: $greyWhite;
    }
    .desktop-rates {
        .list-rates {
            height: 220px;
            overflow-y: auto;
        }
        .rates-header {
            display: flex;
            padding: 15px 16px;
            background: $lightMain;
            border-radius: 4px;
            .title {
                width: 25%;
                color: $gunmetal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
            }
        }
        .rates-body {
            background: #f5f7f9;
            padding: 12px 16px;
            border-radius: 4px;
            display: flex;
            margin-top: 8px;
            .subtitle {
                width: 25%;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $gunmetal;
            }
        }
    }
    .tablet-rate {
        display: none;
    }
    @media (max-width: $lg) {
        width: 600px;
    }
    @media (max-width: 767px) {
        width: 320px;
        .desktop-rates {
            display: none;
        }
        .tablet-rate {
            display: block;
            .rates-body {
                display: flex;
                flex-direction: column;
                border: 8px solid #f5f7f9;
                border-radius: 8px;
                margin-bottom: 8px;
            }
            .rate-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px 16px;
                background: #ffffff;
                border-radius: 4px;
                border: 2px solid #f5f7f9;
            }
            .rate-icon {
                margin-right: 20px;
            }
            .rate-text {
                .title {
                    margin-right: 5px;
                    font-size: 14px;
                    font-weight: 600;
                }
                .subtitle {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
}

.driver-modal,
.inactivate-modal {
    width: 400px;
}



.delete-modal {

    @media (min-width: $xs) {
        padding: 8px 16px 16px;
        width: 320px;
    }
    @media (min-width: $md) {
        padding:  16px 32px 32px;
        width: 439px;
    }

    .delete-modal-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-align: center;

        .want-delete {
            color: $gunmetal800;
        }

        .delete-title {
            color: $red;
        }
    }

    .delete-subtitle {
        margin-top: 24px;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: $darkText;
    }

    .delete-modal-buttons-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 25px;
    }

    .delete-button {
        width: 100%;
        height: 48px;
        background: $red;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        border: none;
        outline: none;
    }

    .cancel-button {
        width: 100%;
        height: 48px;
        background: $greyWhite;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $tableHeadText;
        margin-right: 16px;
        border: none;
        outline: none;
    }
}
