
.checkout-page {
  @media (min-width: 320px) {
    padding: 40px 16px;
  }
  @media (min-width: 768px) {
    padding: 40px 45px;
  }
  @media (min-width: 1280px) {
    padding: 24px 42px;
  }

  .checkout-container-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: transparent;

    @media (min-width: 320px) {
      padding: 32px 0;
      flex-direction: column;
    }
    @media (min-width: 768px) {
      padding: 36px 0;
    }
    @media (min-width: 1440px) {
      padding: 46px 0;
      flex-direction: row;
      align-items: start;
    }
  }

  .breadcrumb-wrapper {
    display: flex;
    align-items: center;

    a {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #2A374E;
      text-decoration: none;
    }

    img {
      margin: 0 14px;
    }

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #9D9D9D;
    }
  }

  .form-wrapper {
    width: auto;
    height: 400px;
    background: white;
    border-radius: 8px;
    //margin: 0 auto;
    @media (min-width: 320px) {
      padding: 28px 16px;
      width: 320px;
      height: auto;
    }
    @media (min-width: 768px) {
      padding: 20px 32px;
      width: 498px;
    }
    @media (min-width: 1440px) {
      padding: 20px 32px;
      width: 514px;
      height: 400px;
      margin-right: 18px;
    }

    .title {
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #41586D;
      margin: 12px 0 8px 0;
    }

    .input-style {
      height: 48px;
      width: 100%;
      border: 1px solid #8CA2B8;
      border-radius: 4px;
      padding: 12px 16px;
    }

    .buttons-wrapper {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .next-button {
      max-width: 217px;
      min-width: 130px;
      width: 220px;
      height: 48px;
      background: #0E9594;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      border: none;
    }

    .cancel-button {
      width: 220px;
      height: 48px;
      max-width: 220px;
      min-width: 130px;
      background: #F5F7F9;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #41586D;
      margin-right: 16px;
      border: none;
    }

    .card-element {
      padding: 12px 16px;
      border: 1px solid #7e7e7e;
      border-radius: 8px;
      width: 100%;
      height: 48px;
    }
  }

  .check-wrapper {
    display: flex;
    align-items: flex-start;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 26px;

    a {
      color: #0E9594;
      text-decoration: none;
    }
  }

  .check-text-style {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 8px;
    color: #2B273C;
  }


  .checkout-pay-info-wrapper {
    display: flex;
    flex-direction: column;

    .checkout-pay-info {
      width: auto;
      height: auto;
      background: white;
      border-radius: 8px;
      padding: 16px;

      @media (min-width: 320px) {
        margin: 8px 0 0;
        width: 320px;
      }
      @media (min-width: 768px) {
        width: 499px;
      }
      @media (min-width: 1440px) {
        width: 330px;
        margin: 0;
      }

      .price-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #324557;
      }

      .plane-type {
        margin: 24px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #41586D;
      }

      .items-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        justify-content: flex-start;

        .checkmark-circle {
          width: 24px;
          height: 24px;
          background: #E1F2F3;
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          margin-left: 12px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
      }

      .check-wrapper {
        display: flex;
        align-items: start;
        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        a {
          color: #FF453A;
        }
      }
    }

    .check-info-wrapper {
      padding: 10px;
      width: 328px;
      height: 56px;
      margin-top: 8px;
      background: #E1F2F3;
      border: 1px solid #0E9594;
      border-radius: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #5D7A95;
      @media (min-width: 320px) {
        width: 320px;
        margin-bottom: 10px;
      }
      @media (min-width: 768px) {
        width: 499px;
      }
      @media (min-width: 1440px) {
        width: 328px;
      }

      span {
        color: #41586D;
        margin-left: 4px;
        font-weight: 600;
      }
    }
  }

}