@import 'src/assets/styles/globalStyles';

.add-note-wrapper {
    .truck-tab {
        background-color: #f5f7f9;
    }
    .documents-wrapper {
        .title {
            display: none;
        }
        .sections-wrapper {
            padding: 16px;
            flex-direction: column-reverse !important;
            .upload-docks {
                border: 4px dashed $main;
                margin-left: 0;
                .drag-drop-text {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 28px;
                    letter-spacing: 0em;
                    text-align: left;
                    > span {
                        color: $main;
                    }
                }
            }
        }
    }
}

.maintanence-tabs {
    width: 100%;
    margin-bottom: 16px;
    & .MuiButtonBase-root {
        color: $lightGunmetal;
        flex: 1;
    }
    & .Mui-selected {
        color: $main !important;
        font-weight: 700;
    }
    & .MuiTabs-indicator {
        background-color: $main;
    }
}
.right-modal-wrapper {
    display: flex;
    gap: 16px;

    > div:first-child {
        flex: 1;
    }
    .right-modal {
        transition: all 0.5s;
        padding: 32px;
        box-shadow: 0 44px #fff, 0 -44px #fff, 44px 0 #fff, 44px -44px #fff, 44px 44px #fff;

        h4 {
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: left;
        }
        .mt-16 {
            cursor: pointer;
            margin-top: 16px;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #0e9594;
        }
    }
}

.maintenance-price-row {
    color: #506c84;
}

@media (max-width: 769px) {
    .add-service-button {
        margin-top: 16px;
    }
}
