@import 'src/assets/styles/globalStyles';

.menu-bar-wrapper {
    display: flex;

    .header-wrapper {
        padding: 18px 16px;
        background: $white;
        position: fixed;
        z-index: 1400;
        width: 100%;
        height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .larg-header {
        height: 112px;
    }

    & .MuiLink-root {
        text-decoration-color: transparent;
    }

    & .MuiTypography-root {
        text-decoration-color: transparent;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #506c84;
    }

    .css-1ps4owl-MuiTypography-root-MuiLink-root:hover {
        text-decoration-color: transparent;
    }

    .MuiDrawer-paper {
        width: 200px;
        background: $white;
        border: none;
    }

    .body-wrapper {
        background: $greyWhite;
        height: 100%;
        min-height: 100vh;
        padding: 0;
    }

    .items-wrapper {
        height: 44px;
        margin-bottom: 16px;
        justify-content: center;
    }

    .icon-button {
        position: fixed;
        z-index: 1440;
        height: 36px;
        width: 36px;
        margin-top: 60px;
        margin-left: -15px;
        border-radius: 4px;
        color: $main;
    }

    .items-wrapper:hover {
        background: $white;
        border-radius: 4px;
    }

    .items-wrapper-active {
        height: 44px;
        margin-bottom: 16px;
        background: $lightMain;
        border-radius: 4px;
        justify-content: center;

        .MuiTypography-root {
            color: #0e9594;
        }
    }

    .list-item-icon {
        min-width: 0;
        justify-content: center;
    }

    .list-item-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #506c84;

        & .MuiListItemText-root {
            opacity: 1;
        }
    }

    .list-style {
        margin-top: 80px;
        padding: 16px 6px;
        position: relative;
        left: 0;
    }

    .user-info {
        display: flex;
        align-items: center;
        flex-direction: row;
        .avatar-img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .user-info-wrapper {
        display: flex;
        align-items: center;
    }
}

.mobile-link-list {
    padding: 0 8px;
    .MuiListItemIcon-root {
        min-width: auto;
        margin-right: 10px;
    }
}

.accordion-styles {
    .MuiPaper-elevation {
        @media (min-width: 320px) {
            padding: 0 16px 0 0 !important;
        }
        @media (min-width: 768px) {
            padding: 0 !important;
        }
    }

    .active-accordion {
        background: #e1f2f3;
        border-radius: 4px;

        .accordion-title {
            display: flex;
            align-items: center;
            p {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #506c84;
                margin-left: 10px;
            }
        }
    }

    .MuiAccordion-root {
        background: transparent;
        box-shadow: none;

        @media (min-width: 320px) {
            .MuiAccordion-root:last-of-type {
                padding: 16px;
            }
        }
        @media (min-width: 768px) {
        }
    }

    .MuiAccordionSummary-content {
        padding: 0 18px;
        margin: 0;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 0;
        .biling-down {
            img {
                transform: rotate(180deg);
            }
        }
    }

    .MuiAccordionSummary-root.Mui-expanded {
        min-height: 44px;
        padding: 0;
    }

    .MuiAccordionSummary-root {
        padding: 0;
        min-height: 44px;
    }

    .MuiAccordionDetails-root {
        margin-left: 20px;
        padding: 0;
    }

    .accordion-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .buttons-style-active {
            background: #e1f2f3;
            border-radius: 4px;
            color: #0e9594;
        }

        .buttons-style {
            background: transparent;
            color: #506c84;
        }

        button {
            padding: 0 16px;
            text-align: start;
            width: 100%;
            height: 44px;
            margin-top: 4px;
            background: transparent;
            border: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #506c84;
        }

        @media only screen and (min-width: 768px) {
            .menu-bar-wrapper {
                .header-wrapper {
                    .desktop-logo {
                        display: flex;
                        align-items: center;
                        .logo-open {
                            -webkit-flex: 0 0 auto;
                            -ms-flex: 0 0 auto;
                            flex: 0 0 auto;
                            width: 200px;
                            -webkit-flex-shrink: 0;
                            -ms-flex-negative: 0;
                            flex-shrink: 0;
                            white-space: nowrap;
                            box-sizing: border-box;
                            -webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
                            transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
                            overflow-x: hidden;
                        }
                        .logp-close {
                            -webkit-flex: 0 0 auto;
                            -ms-flex: 0 0 auto;
                            flex: 0 0 auto;
                            width: 100px;
                            -webkit-flex-shrink: 0;
                            -ms-flex-negative: 0;
                            flex-shrink: 0;
                            white-space: nowrap;
                            box-sizing: border-box;
                            -webkit-transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
                            transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
                            overflow-x: hidden;
                        }
                        .desktop-link {
                            .page-name {
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 24px;
                                color: #41586d;
                            }
                        }
                    }
                    .mobile-logo {
                        display: none;
                    }
                    .mobile-drawer {
                        .list-style {
                            padding-top: 40px;
                        }
                    }
                }
            }
        }
    }
}

.accordion-title {
    display: flex;
    align-items: center;
    .biling-down {
        margin-left: 55px;
    }
    .biling-down-open {
        margin-left: 55px;
    }

    p {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #506c84;
        margin-left: 10px;
    }
}

// Mobile Version.
@media only screen and (max-width: 1023px) {
    .menu-bar-wrapper {
        .header-wrapper {
            .desktop-logo {
                display: none;
            }

            .mobile-logo {
                display: flex;
                align-items: center;
                .hanburger-btn {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    border: none;
                    background-color: transparent;
                }
            }
        }

        .desktop-drawer {
            display: none;
        }
    }

    .mobile-drawer {
        & .MuiPaper-elevation {
            padding-top: 80px;
            width: 224px;
        }
    }

    .list-style {
        .logo-item {
            padding-left: 30px;
            padding-right: 20px;
        }

        .link-list {
            margin-top: 10px;
        }

        .items-wrapper {
            margin-bottom: 8px;
        }

        .items-wrapper-active {
            background: $lightMain;
            border-radius: 4px;
            margin-bottom: 8px;
        }
    }
}

// Desktop and Tablet Version.
@media only screen and (min-width: 1024px) {
    .menu-bar-wrapper {
        .header-wrapper {
            .desktop-logo {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .desktop-link {
                    // margin-left: 60px;

                    .page-name {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: #41586d;
                    }
                }
            }

            .mobile-logo {
                display: none;
            }

            .mobile-drawer {
                .list-style {
                    padding-top: 40px;
                }
            }
        }
    }
}

@media only screen and (min-width: 767px) {
    .desktop-link {
        margin-left: 20px;
        .page-name {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #41586d;
        }
    }
}

@media only screen and (max-width: 766px) {
    .desktop-link {
        display: none;
    }
    .mobile-drawer {
        & .MuiPaper-elevation {
            padding-top: 120px;
        }
    }
}
