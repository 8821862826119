@import 'src/assets/styles/globalStyles';

.user-container {
    padding: 42px;
    @media (max-width: $lg) {
        padding: 24px 16px;
    }
    @media (max-width: $md) {
        padding: 16px 0px;
    }
    .desktop-view {
        padding-top: 36px;
    }
    .full-table-styles {
        .table-row {
            cursor: auto;
            &:hover {
                background-color: #ffffff;
            }
        }
    }
}

.mobile-pagination {
    display: none;
}

.filter-container {
    .filter-box {
        & .MuiFormControl-root {
            width: 100%;
        }
    }
    .filter-text {
        padding: 8px;
    }
    .filter-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #324557;
    }
}

@media only screen and (max-width: 1023px) {
    .wrapper-box {
        margin-top: 16px;

        & .MuiPaper-root {
            max-width: min-content;
        }
    }

    .mobile-card {
        display: block;
        margin-top: 16px;
        .menu-btn {
            border: none;
            background-color: transparent;
        }

        .card-box {
            margin-bottom: 4px;
        }
    }
    .menu-container {
        .text-dark {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $gunmetal800;
        }

        .text-red {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $red;
        }
    }
    .filter-container {
        padding: 8px 24px;
    }
    .filter-text {
        padding: 8px;
        .filter-titile {
            color: $gunmetal800;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .filter-box {
        padding: 8px 24px;
        .text-status {
            color: $gunmetal800;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        & .MuiRadio-colorPrimary {
            color: $main;
        }
    }
    .mobile-view {
        .wrapper-header {
            .header-title {
                .title {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .mobile-pagination {
        display: block;
        padding: 16px;
    }
    .mobile-view {
        .card-box {
            .card-content {
                .text-green {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    color: $main;
                    width: 100%;
                }

                .text-bold {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    color: $gunmetal;
                }
                .text-info {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
}

.user-mobile-text {
    display: flex;
    align-items: center;
    margin: 4px 0;
}

@media (max-width: 768px) {
    .MuiTabs-scroller {
        overflow: auto !important;
    }
    .info-tab {
        max-width: 500px;
        overflow-x: scroll;
    }
}
@media (max-width: 520px) {
    .info-tab {
        max-width: 350px;
    }
}
@media (max-width: 350px) {
    .info-tab {
        max-width: 300px;
    }
}
