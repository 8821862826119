@import 'src/assets/styles/globalStyles';

.add-stops {
    width: 600px;
    max-height: 80vh;
    overflow: auto;

    .date-selector{
      .custom-input{
          padding: 0 18px 0 2px!important;
      }
    }
    @media (max-width: $md) {
        width: 330px;
        height: 560px;
        overflow-y: auto;
    }
}

.load-modal {
    background-color: $white;
    width: 968px;
    .invite-container {
        .footer-box {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            flex-direction: row;
            @media (max-width: $md) {
                justify-content: initial;
                .create-cancel-wrapper {
                    width: 100%;
                }
            }
            .cancel-button,
            .add-button {
                padding: 12px;
                width: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: none;
                @media (max-width: $md) {
                    width: 100%;
                }
            }
        }
    }
}
