// Custom Modal Style.

@import 'src/assets/styles/globalStyles';

// Profile Modal
.profile-modal {
    width: 380px;
    height: 570px;
    overflow: auto;
    position: absolute;
    right: 20px;
    top: 58px;
    padding: 16px;
    border-radius: 8px;
    background: $white;
    @media only screen and (min-width: 1920px) {
        width: 395px;
        padding: 24px;
        right: 40px;
    }
    @media only screen and (max-width: $md) {
        width: 305px;
    }
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: $lightGunmetal;
            height: 48px;
        }
        height: 48px;
        border-color: $lightGunmetal;
        &.Mui-focused fieldset {
            border-color: $lightGunmetal;
        }
    }
    .cancel-profile-btn {
        background: transparent;
        border: none;
        outline: none;
        color: $lightGunmetal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        margin-left: 10px;
    }
    .avatar {
        display: flex;
        align-items: center;
        flex-direction: column;
        .avatar-name {
            .name-info {
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
            }
        }
        .avatar-img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            object-fit: cover;
        }
        .photo {
            background: $lightMain;
            border-radius: 260px;
            padding: 1px;
            position: relative;
            bottom: 32px;
            border: none;
            display: flex;
            left: 22px;
            width: 28px;
            height: 28px;
        }
        .circle-icon {
            color: $greyWhite;
            width: 70px;
            height: 70px;
        }
        .custom-file-upload {
            height: 10px;
        }
        .avatar-name {
            margin-bottom: 18px;
        }
    }
    .edit-user {
        .edit-box {
            padding: 0 16px;
            .edit-btn {
                color: $main;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                background: transparent;
                border: none;
            }
        }

        .group-input {
            width: 100%;
            .form-input {
                margin-bottom: 16px;
                .user-input,
                .email-input {
                    position: relative;
                    left: 16px;
                    top: 42px;
                }
                .name-control {
                    padding: 14px 14px 12px 48px;
                    color: $lightGunmetal;
                    border: 1px solid $lightGunmetal;
                    background-color: $white;
                    :focus-visible {
                        border-color: $gunmetal500;
                        border: none;
                        outline: none;
                    }
                }
                .email-control {
                    padding: 14px 14px 12px 48px;
                    color: $lightGunmetal;
                    border: 1px solid $lightGunmetal;
                    background-color: $white;
                    :focus-visible {
                        border-color: $gunmetal500;
                    }
                }
                .form-control {
                    display: block;
                    width: 100%;
                    line-height: 1.5;
                    color: $gunmetal500;
                    background-color: $white;
                    background-clip: padding-box;
                    border: 1px solid $gunmetal500;
                    border-radius: 4px;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    :focus {
                        border-color: $gunmetal500;
                    }
                    :focus-visible {
                        border-color: $gunmetal500;
                    }
                }
            }
            .user-input,
            .email-input {
                position: relative;
                left: 16px;
                top: 38px;
            }

            .name-control {
                padding: 12px 12px 12px 48px;
                color: $lightGunmetal;
                border: 1px solid $lightGunmetal;
                background-color: $white;
            }
            .email-control {
                padding: 12px 12px 12px 48px;
                color: $lightGunmetal;
                border: 1px solid $lightGunmetal;
                background-color: $white;
            }
        }
    }
    .change-box {
        .save-btn {
            color: $main;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            background: transparent;
            border: none;
        }
        .cancel-btn {
            color: $lightGunmetal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            background: transparent;
            border: none;
        }

        .edit-btn {
            color: $main;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            background: transparent;
            border: none;
        }

        .password-form {
            margin-top: 26px;
            .box-change {
                border: 1px solid $lightGunmetal;
                border-radius: 8px;
                padding: 16px 16px 40px;
                margin-bottom: 16px;
                .title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: $darkText;
                }

                .box-paragraph {
                    margin-top: 16px;
                    .paragraph {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        color: $lightGrey;
                    }
                }
                .group-input {
                    .icon-input {
                        position: relative;
                        left: 16px;
                        top: 40px;
                        width: 30px;
                        height: 30px;
                    }
                    .form-control {
                        display: block;
                        width: 100%;
                        padding: 12px 12px 12px 48px;
                        line-height: 1.5;
                        color: $gunmetal500;
                        background-color: $white;
                        background-clip: padding-box;
                        border: 1px solid $gunmetal500;
                        border-radius: 4px;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        :focus {
                            border-color: $gunmetal500;
                        }
                    }
                    .error-messages {
                        color: $red;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        margin-top: 5px;
                        margin-left: 15px;
                    }

                    .error-border {
                        border: 2px solid $red;
                        border-radius: 8px;
                    }
                }
                .password-box {
                    position: relative;
                    .password-input {
                        position: absolute;
                        right: 12px;
                        top: 40px;
                    }
                }
            }

            .form-banner {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .change-form {
                margin-top: 0px;
                .input-password {
                    height: 75px;
                }
            }
        }
    }
    .sing-box {
        .add-modal-button {
            background-color: $main;
            padding: 12px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: none;
            .button-text {
                margin-left: 10px;
                color: $lightMain;
            }
        }
    }
}

.cangrats-modal {
    background-color: $white;
    margin: 16px;
    width: 430px;
    .invite-container {
        padding: 0 24px;
        .title-box {
            margin-top: 32px;
            text-align: center;
            .title {
                font-weight: 700;
                font-size: 32px;
                line-height: 36px;
                color: $gunmetal800;
                text-align: center;
            }
            .subtitle {
                margin-top: 16px;
                text-align: center;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $gunmetal;
            }
        }
        .footer-box {
            .submit-box {
                margin-top: 32px;
                margin-bottom: 32px;
                .add-modal-button {
                    background: $main;
                    padding: 12px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: none;
                    .button-text {
                        margin-left: 10px;
                        color: $lightMain;
                    }
                    :hover {
                        background-color: $main;
                        background: $main;
                    }
                }
            }
        }
    }
}

.cangrats-reset {
    background-color: $white;
    margin: 16px;
    width: 430px;
    @media (max-width: $md) {
        margin: 0;
    }
    .invite-container {
        padding: 0 24px;

        .title-box {
            margin-top: 32px;
            text-align: center;
            @media (max-width: $md) {
                margin-bottom: 16px;
                margin-top: 16px;
            }
            .title {
                font-weight: 700;
                font-size: 32px;
                line-height: 36px;
                color: $gunmetal800;
                text-align: center;
                @media (max-width: $md) {
                    font-size: 24px;
                }
            }
            .subtitle {
                margin-top: 16px;
                text-align: center;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $gunmetal;
            }
        }
        .footer-box {
            .submit-box {
                margin-top: 32px;
                margin-bottom: 16px;

                @media (max-width: $md) {
                    margin-top: 16px;
                }
                .add-modal-button {
                    background: $main;
                    padding: 12px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: none;
                    .button-text {
                        margin-left: 10px;
                        color: $lightMain;
                    }
                    :hover {
                        background-color: $main;
                        background: $main;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .load-modal {
        width: 672px;
        height: 600px;
        overflow-y: scroll;
        .invite-container {
            padding: 0;
            .sign-form {
                .footer-box {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    gap: 20px;
                    .submit-box {
                        margin-top: 20px;
                        margin-bottom: 20px;
                        width: 100%;
                        .add-modal-button {
                            padding: 12px;
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-transform: none;
                        }
                        // .green-btn {
                        //     text-transform: none;
                        //     background: $main;
                        //     .button-text {
                        //         color: $white;
                        //     }
                        // }
                        .default-btn {
                            text-transform: none;
                            background: $greyWhite;
                            .button-text {
                                color: $tableHeadText;
                            }
                        }
                    }
                }
            }
        }
    }
    .trailer-modal {
        width: 672px;
        margin: 0;
    }
}

// Mobile Version

@media only screen and (max-width: 767px) {
    .inactivate-modal,
    .cangrats-modal,
    .cangrats-reset,
    .truck-modal {
        width: 320px;
        .invite-container {
            padding: 0;
            .title,
            .title-green,
            .title-red {
                font-size: 18px;
                line-height: 28px;
            }
            .title-box {
                .subtitle {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .sign-form {
                margin-top: 16px;
                .submit-box {
                    margin-top: 16px;
                    margin-bottom: 16px;
                    width: 100%;
                }
            }
        }
    }

    .load-modal {
        width: 320px;
        height: 500px;
        overflow-x: scroll;
        margin: 0;
        .invite-container {
            padding: 0;
        }
    }

    .trailer-modal {
        width: 320px;
    }
}
