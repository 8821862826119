// Landing Style.

@import 'src/assets/styles/globalStyles';

.landing-page {
  font-family: NoirProRegular, serif;

  .MuiList-root {
    .dark-link {
      .css-10hburv-MuiTypography-root {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $darkGunmetal;
      }
    }

    .green-link {
      .css-8je8zh-MuiTouchRipple-root {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $main;
      }
    }
  }

  .side-menu {
    @media only screen and (max-width: 768px) {
      padding: 20px;
    }

    .dark-link {
      .css-10hburv-MuiTypography-root {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $darkGunmetal;
      }
    }

    .green-link {
      .css-8je8zh-MuiTouchRipple-root {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $main;
      }
    }
  }

  .landing-header {
    padding: 20px;
    background-repeat: no-repeat;
    background-position: 100%;
    @media (min-width: 320px) and (max-width: 375px) {
      //background-image: url('../../../assets/images/img/banner-375.png');
      //min-height: 394px;
    }

    @media (min-width: 376px) and (max-width: 768px) {
      //background-image: url('../../../assets/images/img/banner-768.png');
      //min-height: 440px;
    }

    @media (min-width: 769px) and (max-width: 1440px) {
      background-image: url('../../../assets/images/img/banner-1440.png');
      min-height: 580px;
    }

    @media only screen and (min-width: 1441px) {
      background-image: url('../../../assets/images/img/bannner-1920.png');
      max-height: 694px;
    }

    .header-container {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .header-box {
      display: flex;
      justify-content: space-between;

      .box-logo {
        padding-top: 10px;
      }

      .dark-link {
        margin-right: 24px;
      }

      .header-links {
        a {
          text-decoration: none;
        }

        @media only screen and (max-width: $lg) {
          display: none;
        }
      }

      .burger-link {
        display: none;
        @media only screen and (max-width: 1023px) {
          display: block;
        }
      }
    }

    .aside-header {
      margin-top: 200px;
      margin-bottom: 178px;
      width: 50%;

      //@media (max-width: 768px) {
      //  display: flex;
      //  flex-direction: column;
      //  align-items: center;
      //  justify-content: center;
      //  text-align: center;
      //}
      @media (max-width: 1440px) {
        width: 100%;
        margin-top: 100px;
        margin-bottom: 100px;
      }
      @media (max-width: $md) {
        display: none;
      }

      .header-title {
        font-size: 48px;
        line-height: 72px;
        font-weight: 600;
        display: flex;
        flex-direction: column;

        .title-we {
          color: $darkGunmetal;
        }

        .title-loads {
          color: $orange;
        }
      }

      .subtitle-box {
        margin-top: 30px;

        .header-subtitle {
          font-style: normal;
          color: $darkGunmetal;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
        }
      }

      .header-get {
        margin-top: 40px;
        max-width: 226px;
      }
    }
  }

  .section-header {
    display: none;
    @media (max-width: $md) {
      display: block;
      margin-top: 40px;

      .aside-header{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }

    .header-title {
      font-size: 48px;
      line-height: 72px;
      font-weight: 600;
      @media (max-width: $md) {
        font-size: 32px;
        line-height: 48px;
      }

      .title-we {
        color: $darkGunmetal;
      }

      .title-loads {
        color: $orange;
        margin-left: 5px;
      }
    }

    .subtitle-box {
      margin-top: 20px;
      @media (max-width: $md) {
        margin-top: 17px;
      }

      .header-subtitle {
        font-style: normal;
        color: $darkGunmetal;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }
    }

    .header-get {
      margin-top: 18px;
      width: 263px;
    }
  }

  .section-banner {
    margin-top: 90px;
    margin-bottom: 90px;
    @media (max-width: $md) {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .card-banner {
      padding: 16px;
      background: $greyWhite;
      border-radius: 8px;

      .card-title {
        padding: 0 16px;
        @media only screen and (max-width: $md) {
          .text-h4 {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }

  .section-everything {
    margin-top: 50px;
    margin-bottom: 80px;

    .title {
      color: $darkGunmetal;
      @media only screen and (max-width: $md) {
        font-size: 36px;
        line-height: 54px;
      }
    }

    .subtitle {
      color: $main;
      @media only screen and (max-width: $md) {
        font-size: 36px;
        line-height: 54px;
      }
    }

    .box-text {
      margin-top: 24px;
      @media (min-width: $xl) {
        margin-right: 100px;
      }
      @media (max-width: $md) {
        margin-top: 16px;
      }

      .type-text {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: $darkGunmetal;
      }
    }

    .order-img {
      @media only screen and (max-width: $md) {
        margin-top: 30px;
        order: 2;
      }
      @media (min-width: 768px) and (max-width: $lg) {
        margin-top: 60px;
        order: 2;
      }
    }

    .order-content {
      margin-top: 40px;
      @media (max-width: $lg) {
        order: 1;
      }
    }
  }

  .section-free {
    background: $main;
    padding: 32px 180px;
    @media (max-width: $lg) {
      padding: 32px 120px;
    }
    @media (max-width: $md) {
      padding: 32px 40px;
    }

    .free-body {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      @media (max-width: $lg) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .free-info {
        display: flex;
        flex-direction: column;
        @media (max-width: $lg) {
          justify-content: center;
          align-items: center;
        }

        .title-box {
          .start-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #ffffff;
          }
        }

        .subtitle-box {
          margin-top: 16px;

          .start-subtitle {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: #ffffff;
            @media (max-width: $md) {
              text-align: center;
            }
          }
        }
      }

      .free-box {
        display: flex;
        align-items: center;
        @media (max-width: $lg) {
          margin-top: 32px;
        }

        .free-btn {
          background: #ffffff;
          border-radius: 4px;
          padding: 12px 87px 12px 86px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #324557;
          border: none;
          outline: none;
          @media (max-width: $lg) {
            margin-top: 32px;
          }
        }
      }
    }
  }

  .icon-flex-start {
    width: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 16px;
    @media (min-width: 600px) {
      width: 100%;
      margin-right: 0;
    }
  }

  .card-features {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 14px;
    @media (min-width: 600px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .section-features {
    margin-top: 120px;
    @media only screen and (max-width: $md) {
      margin-top: 72px;
    }

    .section-title {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      @media only screen and (max-width: $md) {
        margin-bottom: 16px;
      }

      .title {
        font-weight: 600;
        @media only screen and (max-width: $md) {
          font-size: 32px;
          line-height: 48px;
        }
      }

      .subtitle {
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 36px;
        color: $darkGunmetal;
        text-align: center;
        @media only screen and (max-width: $md) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .features-card {
      margin-top: 40px;
      @media only screen and (max-width: $md) {
        .feature-img {
          width: 56px;
          height: 56px;
        }
      }

      .card-title {
        margin-top: 0;
        @media (min-width: 600px) {
          margin-top: 16px;
        }
      }

      .card-description {
        margin-top: 12px;

        .features-parapgraphy {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .section-how {
    margin-top: 120px;
    @media only screen and (max-width: $md) {
      margin-top: 78px;
    }

    .how-title {
      @media (min-width: 1024px) {
        padding-top: 150px;
      }
    }

    .title {
      color: $darkGunmetal;
      @media only screen and (max-width: $md) {
        font-size: 32px;
        line-height: 48px;
      }
    }

    .card-title {
      margin-top: 32px;
      padding-right: 40px;
      @media (min-width: $xl) {
        margin-right: 140px;
      }
      @media only screen and (max-width: $md) {
        margin-top: 16px;
      }

      .we-pargraphy {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: $darkGunmetal;
      }
    }

    .card-banner {
      margin-top: 60px;
      display: flex;
      @media only screen and (max-width: $md) {
        margin-top: 56px;
      }

      .how-step {
        display: flex;
        padding-bottom: 33px;
        padding-left: 45px;
        @media (max-width: $md) {
          padding-left: 16px;
          padding-bottom: 0;
        }

        .step-line {
          margin-right: 46px;
        }

        .step-content {
          margin-top: 102px;

          @media (max-width: $md) {
            margin-top: 102px;
          }

          .title {
            font-size: 24px;
            line-height: 36px;
          }

          .account-text {
            margin-top: 16px;
            color: #20313f;
            font-weight: 300;
            font-size: 16px;
          }
        }
      }
    }
  }

  .section-faster {
    padding-top: 50px;
    padding-bottom: 50px;

    .faster-title {
      color: $darkGunmetal;
    }
  }

  .section-we {
    margin-top: 120px;
    @media only screen and (max-width: $md) {
      margin-top: 100px;
    }

    .title {
      color: $darkGunmetal;
      @media only screen and (max-width: $md) {
        font-size: 32px;
        line-height: 48px;
      }
    }

    .decision {
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 72px;
      color: $darkGunmetal;
      @media only screen and (max-width: $md) {
        font-size: 32px;
        line-height: 48px;
      }
    }

    .start-subtile {
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 72px;
      color: $main;
      margin-left: 6px;
      @media only screen and (max-width: $md) {
        font-size: 32px;
        line-height: 48px;
      }
    }

    .card-title {
      margin-top: 20px;
      padding-right: 40px;
      @media (min-width: $xl) {
        padding-right: 140px;
      }
      @media only screen and (max-width: $md) {
        padding-right: 0;
        margin-top: 16px;
      }

      .we-pargraphy {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: $darkGunmetal;
        text-align: left;
      }
    }

    .card-banner {
      @media only screen and (max-width: $md) {
        margin-top: 35px;
      }
    }

    .card-we {
      margin-top: 40px;
    }
  }

  .section-save {
    margin-top: 120px;

    @media (min-width: $lg) {
      padding-left: 100px;
      padding-right: 100px;
    }
    @media (min-width: $xl) {
      padding-left: 205px;
      padding-right: 205px;
    }
    @media only screen and (max-width: $md) {
      margin-top: 72px;
    }

    .save-box {
      background: $alphaGunmetal;
      border-radius: 15px;
      padding: 64px 145px;
      @media (max-width: $xl) {
        padding: 64px 115px;
      }
      @media (max-width: $lg) {
        padding: 64px;
      }
      @media (max-width: $md) {
        padding: 32px;
      }

      .save-header {
        display: flex;
        justify-content: center;
        flex-direction: row;
        @media (max-width: 1200px) {
          flex-direction: column;
          justify-content: center;
          text-align: center;
        }
        @media (max-width: $md) {
          display: none;
        }

        .title {
          color: $white;
        }

        .subtitle {
          margin-left: 8px;
          color: $main;
          @media (max-width: $lg) {
            margin-left: 0;
          }
        }
      }

      .header-mobile {
        display: none;
        @media (max-width: $md) {
          display: block;
          text-align: center;
          .title {
            color: $white;
            @media only screen and (max-width: $md) {
              font-size: 32px;
              line-height: 48px;
            }
          }
          .subtitle {
            margin-left: 8px;
            color: $main;
            @media only screen and (max-width: $md) {
              font-size: 32px;
              line-height: 48px;
            }
          }
        }
      }

      .box-description {
        margin-top: 40px;
        margin-bottom: 56px;
        @media only screen and (max-width: $md) {
          margin-top: 24px;
          margin-bottom: 30px;
        }

        .box-paragraphy {
          color: $white;
          text-align: center;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .save-free {
        display: flex;
        justify-content: center;

        .green-btn {
          max-width: 410px;
        }
      }
    }
  }

  .section-pay {
    margin-top: 72px;

    .pay-box {
      display: flex;
      justify-content: center;

      .pay-card {
        width: 302px;
        background: $greyWhite;
        border-radius: 8px;
        padding: 32px 18px 32px 18px;
        margin: 12px;

        .pay-title {
          margin-bottom: 20px;
        }

        .pay-subtitle {
          margin-top: 20px;
        }

        .pay-info {
          margin-top: 32px;
          margin-bottom: 24px;

          .pay-number {
            color: $darkGunmetal;
            font-size: 32px;
            font-weight: 700;
            line-height: 48px;
          }

          .pay-mouth {
            color: $neutralGrey;
            font-size: 18px;
            font-weight: 700;
            line-height: 48px;
          }
        }

        .pay-list {
          margin-top: 32px;

          .css-hhvo2v-MuiListItem-root {
            padding-right: 8px;
            padding-left: 8px;
            align-items: center;
          }

          .css-1m6607n-MuiListItemAvatar-root {
            margin-top: 0;
            min-width: 33px;
          }

          .basic-first {
            //position: relative;
            //bottom: 7px;
          }
        }
      }

      .pay-container {
        display: flex;
        justify-content: initial;

        @media (max-width: $lg) {
          flex-direction: column;
        }
      }
    }
  }

  .section-contact {
    background: $darkGunmetal;
    color: $white;
    margin-top: 250px;
    @media (max-width: $md) {
      margin-top: 72px;
    }

    .contact-description {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) and (max-width: $lg) {
        margin: 0 110px;
      }
      @media (min-width: 1025px) and (max-width: 1199px) {
        margin: 0 110px;
      }

      @media only screen and (max-width: $md) {
        margin: 12px;
      }

      .content-titile {
        margin-top: 70px;
        margin-bottom: 24px;
      }

      .title-text {
        max-width: 434px;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .send-messages {
      background-color: $greyWhite;
      padding: 24px;
      border-radius: 8px;
      max-width: 533px;

      @media (min-width: 768px) and (max-width: 1199px) {
        margin: 50px 110px;
      }
      @media (min-width: 1200px) {
        position: relative;
        z-index: 100;
        bottom: 150px;
        width: 100%;
        padding: 24px;
      }
      @media only screen and (max-width: $md) {
        margin: 48px 8px 60px 8px;
        padding: 12px;
      }

      .send-title {
        color: $darkGunmetal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        @media only screen and (max-width: $md) {
          font-size: 24px;
        }
      }

      .group-input {
        padding-top: 16px;
      }

      .send-btn {
        margin-top: 16px;
      }
    }

    @media only screen and (min-width: 1200px) {
      .css-123168l-MuiGrid-root {
        height: 500px;
      }
    }
  }

  .dark-link {
    color: $darkGunmetal;
    text-transform: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .full-img {
    width: 100%;
  }
}

.icon-title-wrapper{
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;

  p{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #20313F;
    margin-left: 16px;
  }
}
