@import 'src/assets/styles/globalStyles';

.trailer-modal {
    background-color: $white;
    width: 463px;

    .invite-container {
        .buttons-end {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
        .edit-group {
            .form-control {
                display: block;
                width: 100%;
                padding: 12px 16px 12px 16px;
                line-height: 1.5;
                color: $gunmetal500;
                background-color: $white;
                background-clip: padding-box;
                border: 1px solid $gunmetal500;
                border-radius: 4px;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                :focus {
                    border-color: $gunmetal500;
                }
            }
        }
        .group-input {
            .name-control {
                color: $lightGunmetal;
                border: 1px solid $lightGunmetal;
                background-color: $white;
                margin-bottom: 16px;
            }
            .email-control {
                color: $lightGunmetal;
                border: 1px solid $lightGunmetal;
                background-color: $white;
                margin-bottom: 16px;
            }
        }
        .footer-box {
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            grid-gap: 16px;
            .submit-box {
                margin-top: 20px;
                margin-bottom: 20px;
                @media (max-width: $md) {
                    width: 100%;
                }

                .add-modal-button,
                .add-button {
                    padding: 12px;
                    width: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: none;
                    @media (max-width: $md) {
                        width: 100%;
                    }
                }
                // .green-btn {
                //     text-transform: none;
                //     background: $main;
                //     .button-text {
                //         color: $white;
                //     }
                // }
                .default-btn {
                    text-transform: none;
                    background: $greyWhite;
                    .button-text {
                        color: $tableHeadText;
                    }
                }
            }
        }
    }
}
