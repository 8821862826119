// Modal Global Style.

@import 'src/assets/styles/globalStyles';

.custom-modal {
    z-index: 1000;
    background: $white;
    border-radius: 16px;

    .close-button {
        border: none;
        background-color: transparent;
    }
}

.modal-width {
    @media (min-width: $xs) {
        width: 311px;
    }
    @media (min-width: $md) {
        width: 400px;
    }
}

// Modal Global Style.

.custom-modal {
    z-index: 1000;
    border-radius: 16px;

    .close-button {
        border: none;
        background-color: transparent;
    }
}

.close-button {
    border: none;
    background-color: transparent;
    padding: 0;
}

.modal-fragment {
    height: auto;
    background: $white;
    border-radius: 8px;
    z-index: 9999;
    position: relative;
    max-height: 80vh;
    overflow: auto;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e7effb;
    padding: 16px;
    @media (min-width: 768px) {
        padding: 16px 32px 32px;
    }
}

.modal-header-no-padding {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 0;
}

.custom-modal-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #324557;
}

.modal-body {
    padding: 16px;
    @media (min-width: 768px) {
        padding: 16px 32px 32px;
    }
}

//
//.delete-modal-fragment {
//    width: 484px;
//    height: auto;
//    background: $white;
//    border-radius: 8px;
//    z-index: 9999;
//    position: relative;
//}
