@import 'src/assets/styles/globalStyles';


.card-head{
    .fuel-box {
        margin-top: 0px;
        padding: 14px 40px 14px 14px;
        background: #E1F2F3;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #506C84;
    }
}
.customers-container {
    padding: 42px;
    @media (max-width: $lg) {
        padding: 24px 16px;
    }
    @media (max-width: $md) {
        padding: 16px 0px;
    }
    .desktop-view {
        padding-top: 36px;
        display: block;
    }
    .mobile-view {
        display: none;
    }
}

.customer-info {
    .customer-header {
        background: $white;
        padding: 24px 24px 0px 24px;
        .customer-banner {
            display: flex;
            justify-content: space-between;
            .banner-box {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            .title {
                font-size: 24px;
                font-weight: 700;
                line-height: 36px;
            }
            .header-btn {
                .add-button-style {
                    .add-pluss {
                        margin-right: 10px;
                        font-size: 22px;
                        margin-bottom: 5px;
                    }
                }
                .popper-btn {
                    .popper-wrapper {
                        position: relative;
                        bottom: 0;
                        right: 15px;
                    }
                }
                .popper-desktop {
                    display: block;
                    @media (max-width: $lg) {
                        display: none;
                    }
                }
                .popper-mobile {
                    display: none;
                    @media (max-width: $lg) {
                        display: block;
                    }
                }
            }
        }
        .customer-tab {
            padding-top: 24px;
            .tab-btns {
                width: 100%;
                & .MuiButtonBase-root {
                    color: $lightGunmetal;
                }
                & .Mui-selected {
                    color: $main;
                    font-weight: 700;
                    font-size: 16px;
                }
                & .MuiTabs-indicator {
                    background-color: $main;
                }
            }
        }
    }
    .customer-main {
        .tab-btns {
            width: 100%;
            & .MuiButtonBase-root {
                color: $lightGunmetal;
            }
            & .Mui-selected {
                color: $main;
                font-weight: 700;
            }
            & .MuiTabs-indicator {
                background-color: $main;
            }
        }
        .details-container {
            padding-bottom: 24px;
            .details-form {
                .edit-load {
                    color: $main;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    margin-left: 10px;
                }
                .save-load {
                    color: $main;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
                .cance-load {
                    color: $tableHeadText;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
                .btn-space {
                    margin-left: 10px;
                    margin-right: 10px;
                }
                .load-form {
                    background: $white;
                    border-radius: 8px;
                    padding: 16px;
                    .load-title {
                        margin-top: 16px;
                        margin-bottom: 24px;
                        .load-basic {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 28px;
                            color: $gunmetal800;
                        }
                    }
                }
                .histery-box {
                    .history-titile {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 36px;
                        color: $gunmetal800;
                    }
                    .close-btn {
                        background: transparent;
                        border: none;
                    }
                    .subtitle-box {
                        margin-top: 24px;

                        .history-subtitle {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 20px;
                            color: $orange;
                        }
                    }
                    .card-box {
                        margin-top: 24px;
                        display: flex;
                        flex-direction: column;
                        .list-history {
                            display: flex;
                            height: 44px;
                            width: 100%;
                            margin-top: 4px;
                            .history-time {
                                padding: 12px 16px;
                                width: 30%;
                                background: $lightMain;
                                border-radius: 4px 0px 0px 4px;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                justify-content: start;
                            }
                            .history-price {
                                padding: 12px 16px;
                                width: 70%;
                                background: $greyWhite;
                                border-radius: 0px 4px 4px 0px;
                                display: flex;
                                justify-content: start;
                                align-items: center;
                            }
                        }
                    }
                }

                .basic-banner {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
        .mobile-loads {
            display: none;
        }
    }
    & .MuiButtonBase-root {
        text-transform: capitalize;
    }
}

.customer-history {
    .history-titile {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: $gunmetal800;
    }
    .close-btn {
        background: $greyWhite;
        border-radius: 32px;
    }
    .history-box {
        margin-top: 10px;
        .btn-history {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $main;
            text-decoration-line: underline;
            background: transparent;
            border: none;
        }
    }
}

.rate-container {
    background: $white;
    border-radius: 8px;
    padding: 16px;
    .customer-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: $gunmetal800;
    }
    .contact-header {
        border-radius: 0px 4px 4px 0px;
        @media (max-width: 1023px) {
            display: none;
        }
        .card-body {
            background: #e1f2f3;
        }
        .fuel-title {
            color: #506c84;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .basic-title {
        .add-rate {
            background: transparent;
            padding: 6px 24px;
            border: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            .rate-title {
                margin-left: 5px;
                color: $main;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    .post-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 16px;
        .post-btn {
            background: transparent;
            border: none;
            .post-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-decoration-line: underline;
                color: $gunmetal500;
            }
        }
    }
    .fuel-body {
        padding-top: 8px;

        .card-header {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 15px 60px 15px 16px;
            background: $lightMain;
            border-radius: 4px;
            .title {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: $gunmetal;
            }
        }
        .card-body {
            .fuel-box {
                margin-top: 0px;
                padding: 14px 40px 14px 14px;
                background: $greyWhite;
                border-radius: 4px;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;

                .fuel-phone {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: $gunmetal;
                }
                .pay-box {
                    margin-right: 10px;
                }
            }
            .edit-btn {
                background: transparent;
                border: none;
                margin-right: 5px;
            }
            .delete-btn {
                background: transparent;
                border: none;
            }
        }
        .fuel-text {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $tableHeadText;
        }
        .fuel-action {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 70px;
        }
    }

    .data-container {
        .data-img {
            margin-top: 0;
        }
    }
}

.stops-mobile {
    display: none;
}

.customers-container {
    .mobile-view {
        display: none;
    }
}

@media only screen and (max-width: 1023px) {
    .stops-container {
        display: block;
        background: $white;
        border-radius: 8px;
        padding: 8px 0 16px;

        .accordion-box {
            margin-bottom: 8px;
        }
        .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before{
            background: transparent;
        }
        .MuiAccordionSummary-root.Mui-expanded {
            min-height: 76px;
            background: #e1f2f3;
            border-radius: 0;
        }
        & .MuiAccordion-root{
            box-shadow: none;

        }
        & .MuiAccordionSummary-root {
            background: $greyWhite;
            border-radius: 4px 0px 0px 4px;
            box-shadow: none;
        }
        & .MuiAccordionSummary-content {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        //& .MuiPaper-root-MuiAccordion-root.Mui-expanded{
        //    background: red!important;
        //}
        & .MuiAccordionSummary-root {
            background: $greyWhite;
            border-radius: 4px 4px;
        }
        & .MuiAccordionSummary-content {
            display: flex;
        }
        .title-box {
            width: 50%;
            .stop-title {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: $tableHeadText;
            }
        }
        .pick-box {
            width: 60%;
            align-items: center;
            display: flex;
            .pick-header {
                display: flex;
                flex-direction: column;
                padding-left: 8px;
                .pick {
                    display: flex;
                    padding-bottom: 5px;
                }
                .pick-text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: $gunmetal800;
                }
                .pick-title {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: $darkElectric;
                }
            }
        }
        .action-box {
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;
            .edit-icon {
                margin: 0 10px;
            }
            .delete-icon {
                margin: 0 10px;
            }
        }
        .details-titile {
            padding: 16px;
            background: $white;
            width: 100%;
        }
        .details-box {
            display: flex;
            background: $lightMain;
            padding: 16px;
            .stops-details {
                width: 100%;
                .details-space {
                    padding-bottom: 10px;
                }
            }
            .details-description {
                padding: 16px;
                background: $white;
            }
            .text-bold {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            }
            .text-info {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin-left: 5px;
            }
            .title {
                display: flex;
            }
        }
        .details-mobile {
            display: none;
        }
        .mobile-box {
            display: none;
        }
    }
    .contact-body {
        display: none;
    }
    // .customers-container {
    //     .mobile-pagination,
    //     .mobile-view {
    //         display: block;
    //     }
    //     .desktop-view {
    //         display: none;
    //     }
    // }
}

@media only screen and (max-width: 767px) {
    .customer-info {
        .customer-header {
            .customer-banner {
                .banner-box {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
                .header-btn {
                    margin-top: 24px;
                    .input-wrapper {
                        .select-dropdown {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .customer-main {
            .desktop-loads {
                display: none;
            }
            .mobile-loads {
                display: block;
            }
            .details-container {
                .details-form {
                    .basic-banner {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .edit-load {
                        display: none;
                    }
                }
            }
        }
    }
    .customer-banner {
        .header-btn {
            .add-button-style {
                width: 48px;
                .add-pluss {
                    font-size: 18px;
                    margin-right: 0;
                    margin-bottom: 0;
                }
                .add-text {
                    display: none;
                }
            }
        }
    }
    .customer-tab {
        .details-container {
            padding: 16px;
        }
        & .MuiButtonBase-root {
            align-items: baseline;
        }
        .loads-container {
            .totals-header {
                .totals-trafic {
                    grid-template-columns: max-content;
                    .loads-box {
                        display: none;
                    }
                }
            }
            .loads-table {
                margin-top: 16px;
                .details-box {
                    padding: 16px;
                    .table-details {
                        padding: 12px;
                        background-color: $greyWhite;
                        display: grid;
                        grid-template-columns: auto auto auto auto;
                        grid-gap: 20px;
                        .title {
                            color: $gunmetal;
                            font-size: 14px;
                        }
                        .number {
                            color: $lightGunmetal;
                            font-size: 14px;
                        }
                    }
                }
                .accordion-header {
                    width: 100%;
                }

                .profit-header {
                    .profit-menu {
                        .popper-wrapper {
                            position: relative;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
            .tabls-header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                .type-header {
                    font-size: 18px;
                    line-height: 28px;
                    display: flex;
                    .load-id {
                        font-weight: 700;
                        color: $gunmetal800;
                    }
                    .load-text {
                        margin-left: 5px;
                        font-weight: 700;
                        color: $gunmetal800;
                    }
                }
                .profit-header {
                    display: flex;
                    align-items: center;
                    .profit-number {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        margin-right: 15px;
                    }
                    .profit-menu {
                        display: block;
                        margin: 0 15px;
                        .menu-btn {
                            border: none;
                            background-color: transparent;
                        }
                    }
                }
            }
            .panel-mobile {
                display: grid;
                grid-template-columns: auto auto auto;
                grid-gap: 16px;
                .panel-row {
                    display: flex;
                    align-items: center;
                    .profit-number {
                        display: flex;
                        padding-left: 10px;
                    }
                }
            }
        }
    }
    .customers-container {
        .desktop-view {
            display: none;
        }
        .mobile-loads {
            display: block;
        }
        .mobile-pagination,
        .mobile-view {
            display: block;
        }

        .mobile-loads {
            display: block;
        }
    }
    .stops-container {
        .mobile-box {
            display: block;
            background:  #e1f2f3;
        }
        .to-do {
            display: none;
        }
    }
    .rate-container {
        .fuel-body {
            .card-body {
                .fuel-box {
                    padding: 14px;
                }
            }
        }
    }
}
