.plans-and-pricing-fragment {
    width: 100%;
    cursor: default;
    padding: 32px 42px;

    @media (max-width: $md) {
        padding: 32px 16px;
    }
    .plans-and-pricing-title {
        font-family: Open Sans, sans-serif;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #324557;
    }
    .cards {
        display: flex;
        justify-content: center;
        margin: 48px 0px;
        @media (max-width: 768px) {
            justify-content: center;
            margin: 24px 0;
        }
        //@media (min-width: 1279px) {
        //  justify-content: flex-start;
        //}
        .cards-wrapper {
            display: flex;
            justify-content: initial;
            @media (max-width: 1279px) {
                flex-direction: column;
                align-items: flex-start;
                // margin-left: 22px;
            }
            .card {
                width: 302px;
                // height: 655px;
                padding: 32px 20px;
                text-align: start;
                margin: 0 auto;
                background: #ffffff;
                border-radius: 8px;
                @media (max-width: 1279px) {
                    width: 300px;
                }
                &:not(:first-of-type) {
                    @media (min-width: 320px) {
                        margin-left: 0;
                        margin-top: 16px;
                    }
                    @media (min-width: 1279px) {
                        margin-top: 0;
                        margin-left: 32px;
                    }
                }
            }
            .title {
                font-style: normal;
                font-weight: 800;
                font-size: 28px;
                line-height: 42px;
                color: #324557;
            }
            .sub-title {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                margin: 16px 0px;
                min-height: 30px;
                color: #324557;
            }
            .type {
                font-style: normal;
                .card-price {
                    font-weight: 700;
                    font-size: 32px;
                    color: #324557;
                }
                .card-sub-price {
                    font-weight: 700;
                    font-size: 16px;
                    color: #324557;
                }
                .time {
                    font-weight: 700;
                    color: #abbdcf;
                    font-size: 16px;
                }
            }
            .get-button {
                width: 261px;
                height: 48px;
                background: #0e9594;
                border-radius: 8px;
                margin: 24px 0 40px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: white;
                text-transform: capitalize;
                border: none;
            }
            .subscribed-button {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #41586d;
                text-transform: capitalize;
                width: 261px;
                height: 48px;
                background: transparent;
                border-radius: 8px;
                margin: 24px 0 40px;
                border: 1px solid #2a374e;
            }
            .packages {
                li {
                    align-items: center;
                    list-style-type: none;
                    display: flex;
                    margin-bottom: 16px;
                    .svgDiv {
                        width: 24px;
                    }
                    p {
                        margin-left: 12px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #20313f;
                    }
                }
            }
            .space-packages {
                padding: 8px 0;
            }
        }
        .basic-vector {
            position: relative;
            bottom: 10px;
        }
    }
}
