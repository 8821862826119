@import 'src/assets/styles/globalStyles';

.wrapper-header {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: $gunmetal800;
    }
    .header-btn {
        .add-button-style {
            width: 40px;
            height: 40px;
        }
    }
    @media only screen and (min-width: 767px) {
        display: none;
    }
}

.data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .data-img {
        margin-top: 120px;
    }
    .data-box {
        display: flex;
        flex-direction: column;
        .title-box {
            margin-top: 16px;
            .date-title {
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: #41586d;
            }
        }
        .subtitle-box {
            margin-top: 16px;
            .date-subtitle {
                display: flex;
                flex-direction: column;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                color: #506c84;
            }
        }
    }
}

.wrapper-btn {
    width: 100%;
    justify-content: flex-end;
    @media (min-width: 320px) {
        // display: none;
    }
    @media (min-width: 767px) {
        display: flex;
    }

    .add-button-style {
        background: $main;
        height: 48px;
        border-radius: 4px;
        width: 212px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        &:hover {
            background: $darkGreen;
        }
    }
}
