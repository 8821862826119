@import 'src/assets/styles/globalStyles';

.trailer-container {
    padding: 42px;
    @media (max-width: $lg) {
        padding: 24px 16px;
    }
    @media (max-width: $md) {
        padding: 16px 0px;
    }
    .desktop-view {
        padding-top: 36px;
    }
}

.trailer-info {
    .trailer-header {
        background: $white;
        padding: 24px 24px 0px 24px;
        .trailer-banner {
            display: flex;
            justify-content: space-between;
            .title {
                font-size: 24px;
                font-weight: 700;
                line-height: 36px;
            }
            .header-btn {
                .add-button-style {
                    .add-pluss {
                        margin-right: 10px;
                        font-size: 22px;
                        margin-bottom: 5px;
                    }
                }
                .popper-btn {
                    .popper-wrapper {
                        position: relative;
                        bottom: 0;
                        right: 15px;
                    }
                }
                .popper-desktop {
                    display: block;
                    @media (max-width: $lg) {
                        display: none;
                    }
                }
                .popper-mobile {
                    display: none;
                    @media (max-width: $lg) {
                        display: block;
                    }
                }
            }
        }
        .trailer-tab {
            padding-top: 24px;
            .tab-btns {
                width: 100%;
                & .MuiButtonBase-root {
                    color: $lightGunmetal;
                    font-size: 16px;
                    font-weight: 600;
                }
                & .Mui-selected {
                    color: $main;
                    font-weight: 700;
                    font-size: 16px;
                }
                & .MuiTabs-indicator {
                    background-color: $main;
                }
            }
            .loads-container {
                padding-top: 24px;
                .totals-header {
                    background: $white;
                    border-radius: 4px;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    text-align: center;
                    padding: 16px;
                    align-items: center;
                    .totals-titile {
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 28px;
                        color: $gunmetal800;
                    }
                    .totals-trafic {
                        display: flex;
                        // grid-template-columns: auto auto auto;
                        grid-gap: 22px;
                        .loads-box {
                            display: flex;
                            flex-direction: row;
                            padding: 10px 16px;
                            background: $greyWhite;
                            border-radius: 4px;
                            .loads-title {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 20px;
                            }
                            .loads-number {
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 20px;
                                margin-left: 5px;
                            }
                        }
                        .profit-box {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 10px 16px;
                            background: $greyWhite;
                            border-radius: 4px;
                            .profit-title {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 20px;
                            }
                            .profit-number {
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 20px;
                                margin-left: 5px;
                            }
                        }
                        .total-box {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 10px 16px;
                            background: $lightOrange;
                            border-radius: 4px;
                            .total-title {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 20px;
                                color: $orange;
                            }
                            .total-number {
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 20px;
                                margin-left: 5px;
                                color: $orange;
                            }
                        }
                    }
                }
                .profit-info {
                    display: none;
                }
                .panel-images {
                    display: none;
                }
            }
            .details-container {
                padding: 24px 0;
                .details-form {
                    .edit-load {
                        color: $main;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        margin-left: 10px;
                    }
                    .save-load {
                        color: $main;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                    }
                    .cance-load {
                        color: $tableHeadText;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                    }
                    .btn-space {
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                    .load-form {
                        background: $white;
                        border-radius: 8px;
                        padding: 16px;
                        .load-title {
                            //margin-top: 16px;
                            margin-bottom: 24px;
                            .trailer-basic {
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 28px;
                                color: $gunmetal800;
                            }
                            .load-basic {
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 28px;
                                color: $gunmetal800;
                            }
                        }
                        .edit-group {
                            select {
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                background: transparent url('../../assets/images/svg/arrow-down-select.svg') no-repeat 98% center;
                            }
                            select::-ms-expand {
                                display: none;
                            }
                            .form-control {
                                display: block;
                                width: 100%;
                                padding: 12px 16px 12px 16px;
                                line-height: 1.5;
                                color: $gunmetal500;
                                background-clip: padding-box;
                                border: 1px solid $gunmetal500;
                                border-radius: 4px;
                                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                                :focus {
                                    border-color: $gunmetal500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .trailer-main {
        padding: 24px;
        .tab-btns {
            width: 100%;
            & .MuiButtonBase-root {
                color: $lightGunmetal;
            }
            & .Mui-selected {
                color: $main;
                font-weight: 700;
            }
            & .MuiTabs-indicator {
                background-color: $main;
            }
        }
        .details-container {
            padding-bottom: 24px;
            .details-form {
                .edit-load {
                    color: $main;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    margin-left: 10px;
                }
                .save-load {
                    color: $main;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
                .cance-load {
                    color: $tableHeadText;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
                .btn-space {
                    margin-left: 10px;
                    margin-right: 10px;
                }
                .load-form {
                    background: $white;
                    border-radius: 8px;
                    padding: 16px;
                    .load-title {
                        //margin-top: 16px;
                        margin-bottom: 24px;
                        .trailer-basic {
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 28px;
                            color: $gunmetal800;
                        }
                        .load-basic {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 28px;
                            color: $gunmetal800;
                        }
                    }
                }
            }
        }
    }
    & .MuiButtonBase-root {
        text-transform: capitalize;
    }
}

@media only screen and (max-width: 1023px) {
    .mobile-view {
        .wrapper-header {
            .title {
                display: block;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .trailer-info {
        .trailer-main {
            .desktop-loads {
                display: none;
            }
            .mobile-loads {
                display: block;
            }
            .details-container {
                .details-form {
                    .edit-load {
                        display: none;
                    }
                }
            }
        }
    }
    .trailer-banner {
        .header-btn {
            .add-button-style {
                width: 48px;
                .add-pluss {
                    font-size: 18px;
                    margin-right: 0;
                    margin-bottom: 0;
                }
                .add-text {
                    display: none;
                }
            }
        }
    }
    .trailer-tab {
        .details-container {
            padding: 16px;
        }
        & .MuiButtonBase-root {
            align-items: baseline;
        }
    }
    .mobile-loads {
        display: block;
    }
    .loads-container {
        .totals-header {
            .totals-trafic {
                grid-template-columns: max-content;
                .loads-box {
                    display: none;
                }
            }
        }
        .loads-table {
            margin-top: 16px;
            .details-box {
                padding: 16px;
                .table-details {
                    padding: 12px;
                    background-color: $greyWhite;
                    display: grid;
                    grid-template-columns: auto auto auto auto;
                    grid-gap: 20px;
                    .title {
                        color: $gunmetal;
                        font-size: 14px;
                    }
                    .number {
                        color: $lightGunmetal;
                        font-size: 14px;
                    }
                }
            }
            .accordion-header {
                width: 100%;
            }

            .profit-header {
                .profit-menu {
                    .popper-wrapper {
                        position: relative;
                        transform: rotate(90deg);
                    }
                }
            }
        }
        .tabls-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .type-header {
                font-size: 18px;
                line-height: 28px;
                display: flex;
                .load-id {
                    font-weight: 700;
                    color: $gunmetal800;
                }
                .load-text {
                    margin-left: 5px;
                    font-weight: 400;
                    color: $gunmetal800;
                }
            }
            .profit-header {
                display: flex;
                align-items: center;
                .profit-number {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    margin-right: 15px;
                }
                .profit-menu {
                    display: block;
                    margin: 0 15px;
                    .menu-btn {
                        border: none;
                        background-color: transparent;
                    }
                }
            }
        }
        .panel-mobile {
            display: grid;
            grid-template-columns: initial;
            grid-gap: 16px;
            .panel-row {
                display: flex;
                align-items: center;
                .profit-number {
                    display: flex;
                    padding-left: 10px;
                }
            }
        }
    }
}




.trailer-cost-container {
    .load-subtitle {
        margin-bottom: 16px;
        .subtitile-basic {
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: #324557;
        }
    }
    .cost-summary {
        margin-bottom: 24px;
        .fuel-card {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 12px 16px;
            background: #f5f7f9;
            border-radius: 8px;
            .img-box {
                margin-right: 16px;
            }
            .text-fuel {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #0e9594;
            }
            .text-info {
                margin-left: 16px;

                font-size: 16px;
                line-height: 24px;
            }
            .text-bold {
                font-weight: 700;
                color: #324557;
            }
            .text-gallon {
                color: #8ca2b8;
            }
        }
    }
    .fix-cost {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}