@import 'src/assets/styles/globalStyles';

.load-container {
  padding: 42px;
  @media (max-width: $md) {
    padding: 36px 16px;
  }
  @media (max-width: $sm) {
    padding: 24px 16px;
  }

  .load-view {
    padding-top: 36px;
  }
}

.load-board {
  .loads-title {
    margin-bottom: 12px;
  }

  .load-banner {
    .banner-box {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .box-title {
        display: none;
      }
    }

    .header-btn {
      display: flex;
      flex-direction: row;
      align-items: center;

      .add-button-style {
        .add-pluss {
          margin-right: 10px;
          font-size: 22px;
          margin-bottom: 5px;
        }
      }

      .filter-menu {
        display: none;
      }
    }

    .header-input {
      width: 228px;
      margin-right: 10px;
    }

    .banner-mobile {
      display: none;
    }

    .filter-menu {
      .filter-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $main;
      }
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
  }

  .load-tab {
    padding-top: 24px;

    .tab-btns {
      width: 100%;

      & .MuiButtonBase-root {
        color: $lightGunmetal;
      }

      & .Mui-selected {
        color: $main;
        font-weight: 700;
      }

      & .MuiTabs-indicator {
        background-color: $main;
      }
    }

    .details-container {
      padding: 24px 0;

      .details-form {
        .edit-load {
          color: $main;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          margin-left: 10px;
        }

        .load-form {
          background: $white;
          border-radius: 8px;
          padding: 16px;

          .loads-title {
            margin-top: 16px;
            margin-bottom: 24px;
          }

          .loads-title {
            margin-top: 16px;
            margin-bottom: 24px;
          }
        }
      }

      .load-error {
        display: flex;
      }
    }
  }

  .tab-container {
    .totals-header {
      background: $white;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      text-align: center;
      padding: 16px;
      align-items: center;

      .totals-titile {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: $gunmetal800;
      }
    }

    .totals-trafic {
      display: flex;
      grid-gap: 22px;

      .number-box {
        display: flex;
        flex-direction: row;
        padding: 10px 16px;
        background: $greyWhite;
        border-radius: 4px;
        width: max-content;

        .loads-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }

        .loads-number {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          margin-left: 5px;
        }
      }

      .profit-box {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 10px 16px;
        background: $greyWhite;
        border-radius: 4px;

        .profit-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }

        .profit-number {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          margin-left: 5px;
        }
      }

      .total-box {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 10px 16px;
        background: $lightOrange;
        border-radius: 4px;

        .total-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $orange;
        }

        .total-number {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          margin-left: 5px;
          color: $orange;
        }
      }
    }

    .profit-info {
      display: none;
    }

    .panel-images {
      display: none;
    }
  }

  .divider {
    margin: 10px 0;
  }

  .mobile-stops,
  .mobile-loads {
    display: none;
  }

  .totals-header {
    background: $white;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: center;
    padding: 16px;
    align-items: center;

    .totals-titile {
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      color: $gunmetal800;
      display: none;
    }

    .totals-trafic {
      display: flex;
      grid-gap: 22px;

      .number-box {
        display: flex;
        flex-direction: row;
        padding: 10px 16px;
        background: $greyWhite;
        border-radius: 4px;
        width: max-content;

        .loads-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }

        .loads-number {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          margin-left: 5px;
        }
      }

      .profit-box {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 10px 16px;
        background: $greyWhite;
        border-radius: 4px;

        .profit-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }

        .profit-number {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          margin-left: 5px;
        }
      }

      .total-box {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 10px 16px;
        background: $lightOrange;
        border-radius: 4px;

        .total-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $orange;
        }

        .total-number {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          margin-left: 5px;
          color: $orange;
        }
      }
    }
  }

  .control-minimal {
    height: 48px;
  }
}

.load-info {
  .load-header {
    background: $white;
    padding: 24px 24px 0px 24px;

    .load-banner {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
      }

      .header-btn {
        .add-button-style {
          .add-pluss {
            margin-right: 10px;
            font-size: 22px;
            margin-bottom: 5px;
          }
        }

        .popper-btn {
          .popper-wrapper {
            position: relative;
            bottom: 0;
            right: 15px;
          }
        }
      }
    }
  }

  .info-main {
    padding: 24px;

    .tab-btns {
      width: 100%;

      & .MuiButtonBase-root {
        color: $lightGunmetal;
      }

      & .Mui-selected {
        color: $white;
        font-weight: 700;
      }

      & .MuiTabs-indicator {
        background-color: $main;
      }
    }

    .details-container {
      padding-bottom: 24px;

      .details-form {
        .edit-load {
          color: $main;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          margin-left: 10px;
        }

        .save-load {
          color: $main;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .cance-load {
          color: $tableHeadText;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .btn-space {
          margin-left: 10px;
          margin-right: 10px;
        }

        .load-form {
          background: $white;
          border-radius: 8px;
          padding: 16px;

          .load-title {
            margin-top: 16px;
            margin-bottom: 24px;

            .load-basic {
              font-weight: 700;
              font-size: 18px;
              line-height: 28px;
              color: $gunmetal800;
            }
          }
        }
      }
    }

    .mobile-loads {
      display: none;
    }
  }

  .details-container {
    .error-submit {
      background: #ffffff;
      border-radius: 8px;
      padding: 16px;

      .text-messages {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: $red;
      }

      .subtitle-messages {
        color: $red;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .details-form {
      .add-stop {
        background: transparent;
        padding: 6px 24px;
        border: none;
        display: flex;
        flex-direction: row;
        align-items: center;

        .stop-title {
          margin-left: 5px;
          color: $main;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

        }
      }
    }

    .load-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }

    .submit-details {
      display: flex;
      flex-direction: row;
      gap: 16px;
      @media (max-width: $md) {
        width: 100%;
        flex-direction: column;
        align-items: center;
      }

      .cancel-button {
        //width: 150px;
        padding: 12px 24px;
        @media (max-width: $md) {
          width: 190px;
          padding: 12px;
        }

        .text-error {
          color: $red;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .add-button {
        @media (max-width: $md) {
          width: 100%;
          padding: 12px;
        }
        padding: 12px 48px;
      }
    }

    .submit-details {
      @media (max-width: $md) {
        width: 100%;
        display: flex;
      }

      .add-button {
        @media (max-width: $md) {
          width: 100%;
          padding: 12px;
        }
        padding: 12px 48px;
      }
    }

    .load-space {
      margin-top: 24px;
    }
  }

  .stops-container {
    height: 340px;
    overflow: auto;

    .accordion-box {

      margin-bottom: 8px;
    }

    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 76px;
      background: #e1f2f3;
    }

    & .MuiAccordionSummary-root {
      background: $greyWhite;
      border-radius: 4px 0px 0px 4px;
    }

    & .MuiAccordionSummary-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .title-box {
      width: 100%;
      @media (min-width: 768px) {
        width: 35%;
      }

      .stop-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $tableHeadText;
        margin-right: 8px;
      }
    }

    .pick-box {
      width: 55%;
      align-items: center;
      display: flex;

      .pick-header {
        display: flex;
        flex-direction: column;
        padding-left: 8px;
        width: 100%;

        .pick {
          display: flex;
          padding-bottom: 5px;
        }

        .pick-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $gunmetal800;
        }

        .pick-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $gunmetal500;
        }
      }
    }

    .action-box {
      display: flex;
      justify-content: flex-end;

      .edit-icon {
        margin: 0 10px;
        width: 24px;
        @media (min-width: 768px) {
          width: 40px;
        }
      }

      .delete-icon {
        // margin: 0 10px;
        width: 24px;
        @media (min-width: 768px) {
          margin-right: 30px;
        }
      }
    }

    .details-titile {
      padding: 16px;
      background: $white;
      border-radius: 4px;
    }

    .details-box {
      display: flex;
      background: $lightMain;
      padding: 16px;

      .stops-details {

        width: 100%;
      }

      .details-description {
        padding: 16px;
        background: $white;
        width: 100%;
        border-radius: 4px;
      }

      .text-green {
        color: $main;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      .text-info {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #41586d;
        margin-left: 2px;
      }

      .title {
        display: flex;
      }
    }

    .details-mobile {
      display: none;
    }

    .data-container {
      .data-img {
        margin-top: 0;
      }

      .data-box {
        text-align: center;
        display: contents;
      }
    }
  }

  .transaction-container {
    .desktop-view {
      padding-top: 0;

      .wrapper-header {
        display: none;
      }
    }

    .mobile-pagination {
      @media (min-width: $lg) {
        display: none;
      }
    }
  }
}

.loads-table {
  .wrapper-header {
    display: none;
  }

  .desktop-address {
    display: block;
  }

  .mobile-address {
    display: none;
  }
}

.filter-container {
  .filter-box {
    padding: 8px;

    .check-label {
      width: 100%;
      min-width: 100%;
      margin: 2px 0;
      padding-left: 16px;

      :hover {
        background: $greyWhite;
      }
    }

    & .MuiFormControlLabel-root {
      min-width: 100%;
      width: 100%;
    }
  }
}

.select-status {

  & .Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: #324557 !important;

    .dropdown-text {
      color: #324557;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  & .MuiInputBase-root {
    background: #f5f7f9;
  }

  fieldset {
    border: none;
  }
}

@media only screen and (max-width: 1280px) {
  .loads-container {
    .loads-table {
      margin-top: 16px;
    }

    .loads-title {
      .totals-titile {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: $gunmetal800;
      }
    }

    .load-mobile {
      margin-top: 16px;

      .loads-table {
        background-color: $white;
        padding: 16px;

        .details-box {
          padding: 16px;

          .table-details {
            padding: 12px;
            background-color: $greyWhite;
            display: grid;
            grid-template-columns: auto auto auto auto;
            grid-gap: 20px;

            .title {
              color: $darkElectric;
              font-size: 14px;
            }

            .number {
              color: $lightGunmetal;
              font-size: 14px;
            }
          }
        }

        .accordion-header {
          width: 100%;
        }

        & .MuiAccordionSummary-expandIconWrapper {
          position: relative;
          top: 5px;
        }

        .type-status {
          .active-text,
          .hold-text,
          .inactive-text,
          .pending-text,
          .transit-text,
          .maintenance-text,
          .hold-text,
          .terminated-text,
          .recruitment-text,
          .canceled-text {
            padding: 2px 8px;
          }
        }
      }
    }

    .tabls-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .type-header {
        font-size: 18px;
        line-height: 28px;
        display: flex;

        .load-id {
          font-weight: 700;
          color: $gunmetal800;
          font-size: 16px;
        }

        .load-text {
          margin-left: 5px;
          font-weight: 400;
          color: $gunmetal800;
          font-size: 16px;
        }
      }

      .profit-header {
        display: flex;
        align-items: center;

        .profit-number {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-right: 25px;
        }

        .profit-menu {
          display: block;
          margin-top: 10px;
          margin-right: 15px;

          .popper-wrapper {
            position: relative;
            right: 5px;
            transform: rotate(90deg);
            bottom: 12px;
          }

          .menu-btn {
            border: none;
            background-color: transparent;
          }
        }
      }
    }

    .panel-mobile {
      display: grid;
      grid-template-columns: auto;
      grid-gap: 16px;

      .panel-row {
        display: flex;
        align-items: center;

        .profit-number {
          display: flex;
          padding-left: 10px;
        }
      }

      .panel-images {
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
      }

      .table-address {
        display: block;
      }

      .mobile-address {
        display: none;
      }

      .profit-address {
        display: none;
      }
    }

    .tabls-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .accordion-body {
      padding-top: 10px;

      .panel-mobile {
        .profit-info {
          display: flex;
          align-items: center;
        }

        .profit-bold {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $gunmetal800;
        }

        .profit-subtitle {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          margin-left: 8px;
        }

        .profit-status {
          display: none;
        }
      }
    }
  }

  .mobile-view {
    .wrapper-header {
      align-items: center;

      .title {
        display: none;
      }

      .header-btn {
        .add-button-style {
          width: 100%;
          padding: 8px;

          .add-text {
            margin-left: 12px;
          }
        }
      }
    }
  }
  .load-board {
    .mobile-loads {
      display: block;
    }

    .load-banner {
      .banner-box {
        justify-content: space-between;
      }

      .header-btn {
        // margin-top: 16px;
        .filter-menu {
          display: block;
          margin-right: 24px;

          .filter-button-style {
            height: 40px;
            border-radius: 4px;
            width: 40px;
            text-align: center;
            border: none;
            background: transparent;
          }
        }
      }
    }
  }
  .desktop-loads {
    display: none;
  }
  .load-container {
    .mobile-pagination {
      display: block;
      margin-top: 32px;
      padding: 0;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .loads-container {
    .loads-table {
      margin-top: 16px;
    }

    .loads-title {
      .totals-titile {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: $gunmetal800;
        display: none;
      }
    }

    .load-mobile {
      margin-top: 16px;

      .loads-table {
        background-color: $white;
        padding: 16px;

        .details-box {
          padding: 16px;

          .table-details {
            padding: 12px;
            background-color: $greyWhite;
            display: grid;
            grid-template-columns: auto auto auto auto;
            grid-gap: 20px;

            .title {
              color: $darkElectric;
              font-size: 14px;
            }

            .number {
              color: $lightGunmetal;
              font-size: 14px;
            }
          }
        }

        .accordion-header {
          width: 100%;
        }

        & .MuiAccordionSummary-expandIconWrapper {
          position: relative;
          top: 5px;
        }
      }
    }

    .tabls-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .type-header {
        font-size: 18px;
        line-height: 28px;
        display: flex;

        .load-id {
          font-weight: 700;
          color: $gunmetal800;
          font-size: 16px;
        }

        .load-text {
          margin-left: 5px;
          font-weight: 400;
          color: $gunmetal800;
          font-size: 16px;
        }
      }

      .profit-header {
        display: flex;
        align-items: center;

        .profit-number {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-right: 25px;
        }

        .profit-menu {
          display: block;
          margin-top: 10px;
          margin-right: 15px;

          .popper-wrapper {
            position: relative;
            right: 5px;
            transform: rotate(90deg);
            bottom: 12px;
          }

          .menu-btn {
            border: none;
            background-color: transparent;
          }
        }
      }
    }

    .panel-mobile {
      display: grid;
      grid-template-columns: auto;
      grid-gap: 16px;

      .panel-row {
        display: flex;
        align-items: center;

        .profit-number {
          display: flex;
          padding-left: 10px;
        }
      }

      .panel-images {
        display: flex;
        padding-left: 20px;
      }

      .table-address {
        display: block;
      }

      .mobile-stops,
      .mobile-address {
        display: none;
      }

      .profit-address {
        display: none;
      }
    }

    .tabls-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .accordion-body {
      .panel-mobile {
        .profit-info {
          display: flex;
          align-items: center;
        }

        .profit-bold {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $gunmetal800;
        }

        .profit-subtitle {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          margin-left: 5px;
        }

        .profit-status {
          display: none;
        }

        .box-status {
          display: none;
        }
      }
    }
  }
  .mobile-view {
    .wrapper-header {
      align-items: center;

      .title {
        display: none;
      }

      .header-btn {
        .add-button-style {
          width: 100%;
          padding: 8px;

          .add-text {
            margin-left: 12px;
          }
        }
      }
    }
  }
  .load-board {
    .mobile-loads {
      display: block;
    }

    .load-banner {
      .banner-box {
        justify-content: space-between;
      }

      .header-btn {
        // margin-top: 16px;
        .filter-menu {
          display: block;
          margin-right: 24px;

          .filter-button-style {
            height: 40px;
            border-radius: 4px;
            width: 40px;
            text-align: center;
            border: none;
            background: transparent;
          }
        }

        .add-button-style {
          width: 40px;
          height: 40px;

          .add-pluss {
            font-size: 18px;
            margin-right: 0;
            margin-bottom: 0;
          }

          .add-text {
            display: none;
          }
        }
      }
    }
  }
  .desktop-loads {
    display: none;
  }
  .load-container {
    .mobile-pagination {
      padding: 0;
      margin-top: 32px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .loads-container {
    .totals-header {
      .totals-titile {
        display: none;
      }

      .totals-trafic {
        display: flex;
        flex-direction: column;
      }
    }

    .loads-title {
      .totals-titile {
        display: none;
      }
    }

    .load-mobile {
      margin-top: 16px;

      .loads-table {
        background-color: $white;
        padding: 16px;

        .details-box {
          padding: 16px;

          .table-details {
            padding: 12px;
            background-color: $greyWhite;
            display: grid;
            grid-template-columns: inherit;
            grid-gap: 20px;

            .title {
              color: $darkElectric;
              font-size: 14px;
            }

            .number {
              color: $lightGunmetal;
              font-size: 14px;
            }
          }
        }

        .accordion-header {
          width: 100%;
        }

        & .MuiAccordionSummary-expandIconWrapper {
          position: relative;
          top: 5px;
        }

        .desktop-address {
          display: none;
        }

        .mobile-address {
          display: flex;
          align-items: center;
        }

        .mobile-stops {
          display: flex;
          align-items: center;
        }
      }

      .type-status {
        display: none;
      }

      .profit-status {
        display: block;

        .status-title {
          margin-left: 15px;
        }
      }
    }

    .tabls-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .type-header {
        font-size: 18px;
        line-height: 28px;
        display: flex;

        .load-id {
          font-weight: 700;
          color: $gunmetal800;
          font-size: 16px;
        }

        .load-text {
          margin-left: 5px;
          font-weight: 700;
          color: $gunmetal800;
          font-size: 16px;
        }
      }

      .profit-header {
        display: flex;
        align-items: center;

        .profit-number {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-right: 25px;
        }

        .profit-menu {
          display: block;
          margin-top: 10px;
          margin-right: 15px;

          .popper-wrapper {
            position: relative;
            right: 5px;
            transform: rotate(90deg);
            bottom: 12px;
          }

          .menu-btn {
            border: none;
            background-color: transparent;
          }
        }
      }
    }

    .panel-mobile {
      .panel-row {
        display: flex;
        align-items: center;

        .profit-number {
          display: flex;
          padding-left: 10px;
        }
      }

      .panel-images {
        display: flex;
        padding-left: 20px;
      }

      .table-address {
        display: block;
      }

      .mobile-address {
        display: none;
      }

      .profit-address {
        display: none;
      }
    }

    .tabls-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .accordion-body {
      .panel-mobile {
        .profit-info {
          display: flex;
          align-items: center;
        }

        .profit-bold {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $gunmetal800;
        }

        .profit-subtitle {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          margin-left: 5px;
        }
      }

      .address-stop {
        width: 100px;
      }

      .grid-box {
        display: grid;
        grid-template-columns: auto auto auto;
      }
    }
  }
  .box-status {
    display: none;
  }
  .load-board {
    .load-banner {
      .banner-box {
        display: flex;

        .box-title {
          width: 100%;
          display: block;
        }

        .header-input {
          display: none;
        }
      }

      .header-btn {
        .filter-menu {
          display: block;
          margin-right: 24px;

          .filter-button-style {
            height: 40px;
            border-radius: 4px;
            width: 40px;
            text-align: center;
            border: none;
            background: transparent;
          }

          .filter-text {
            display: none;
          }
        }

        .add-button-style {
          width: 40px;
          height: 40px;

          .add-pluss {
            font-size: 18px;
            margin-right: 0;
            margin-bottom: 0;
          }

          .add-text {
            display: none;
          }
        }
      }

      .banner-mobile {
        display: block;
        margin-top: 26px;

        .header-input {
          width: 100%;

          .edit-group {
            .form-control {
              padding: 8px 16px;
            }
          }
        }
      }
    }
  }
  .mobile-view {
    .wrapper-header {
      .header-btn {
        .add-button-style {
          width: 40px;
          height: 40px;
        }
      }

      .filter-menu {
        .filter-text {
          display: none;
        }
      }

      .add-text {
        display: none;
      }
    }
  }
  .load-info {
    .load-banner {
      .header-btn {
        margin-top: 16px;
        width: 100%;
      }

      .select-dropdown {
        width: 100%;
      }
    }

    .stops-container {
      //.MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation1 .MuiAccordion-root .MuiAccordion-rounded .MuiAccordion-gutters .accordion-box {
      //    box-shadow: none;
      //}
      .pick-box,
      .action-box {
        display: none;
      }

      .details-description {
        display: none;
      }

      .details-box {
        .stops-details {
          display: flex;
        }
      }

      .details-titile {
        width: 100%;
      }

      .pick-mobile {
        display: block;

        .pick-box {
          display: flex;
        }
      }

      .details-mobile {
        display: block;

        .action-box {
          display: flex;
          padding-right: 0;
        }
      }
    }
  }

  .load-form {
    .documents-wrapper {
      padding: 0;

      .upload-select {
        & .MuiOutlinedInput-input {
          padding-right: 0;
        }
      }
    }
  }

  .loads-table {
    .status-title {
      display: block;
    }
  }
}

.profit-bold {
  white-space: nowrap;
}

.stop-desktop-view {
  display: none;
  @media (min-width: 1280px) {
    display: flex;
    width: 150px;
  }
}

.tablet-and-desktop {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.MuiAccordion-root {
  box-shadow: none !important;
}

.MuiAccordion-root:before {
  background: transparent !important;
}

.MuiAccordion-rounded {
  box-shadow: none !important;
}

.load-cost-wrapper{
  padding: 24px 16px 16px;
  @media (min-width: 768px) {
    padding: 16px 8px 24px;
  }
  @media (min-width: 1279px) {
    padding: 0;
  }
}

.coast-white-wrapper {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 16px;
  @media (min-width: 768px) {
    padding:  24px;
  }

}

.coast-white-wrapper-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: $gunmetal800;
}

.coasts-wrapper {
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  //@media (min-width: 1279px) {
  //  margin-top: 24px;
  //}
}

.coasts-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: $gunmetal800;
  margin: 16px 0;
}

.gray-width {
  max-width: 100%;

  @media (min-width: 768px) {
    max-width: 33%;
  }
}

.coast-card-gray {
  background: $greyWhite;
  border: 1px solid #E7EFFB;
  margin-right: 0;
  margin-bottom: 16px;
  @media (min-width: 768px) {
    margin-right: 16px;
    margin-bottom: 0;
  }
}

.price-text{
  font-weight: 400;
  text-transform: lowercase;
  color:#41586D;
}

.coast-card {
  border-radius: 8px;
  padding: 12px 16px;
  height: 92px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.coast-card-green {
  background: $lightMain;
}

.card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $gunmetal500;
  margin-left: 8px;
}

.amount-card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $gunmetal;
}

.card-price {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $gunmetal800;
  margin-top: 16px;
}

.card-response {
  display: flex;
  width: 100%;
}

.desktop-and-mobile {
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
  @media (min-width: 1279px) {
    display: block;
  }
}

.tablet{
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
    @media (min-width: 1279px) {
        display: none;
    }
}