@import 'src/assets/styles/globalStyles';

.documents-wrapper {
    width: 100%;
    min-height: 377px;
    background: $white;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 22px;
    height: auto;

    @media (min-width: 768px) {
        padding: 16px;
    }

    .select-status {
        width: 33px;
        display: flex;
        justify-content: center;
    }

    .sections-wrapper {
        display: flex;
        @media (min-width: $xs) {
            flex-direction: column-reverse;
        }

        @media (min-width: 1280px) {
            flex-direction: row;
        }
    }

    .title {
        margin-bottom: 24px;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: $gunmetal800;
    }

    .item-loading {
        width: 100%;
        height: 56px;
        background: $greyWhite;
        border-radius: 4px;
        padding: 26px 16px;
        margin-bottom: 8px;
    }

    .item {
        width: 100%;
        height: 56px;
        background: $greyWhite;
        border-radius: 4px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        align-items: center;
    }

    .document-items-wrapper {
        height: 202px;
        width: 100%;
        overflow: auto;

        .item-title {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $darkText;
        }

        .buttons-wrapper {
            display: flex;

            button {
                background: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                margin-right: 10px;
            }
        }
    }

    .uploaded-docks {
        background: #ffffff;
        box-shadow: 0px 0px 6px rgba(138, 138, 138, 0.16);
        border-radius: 8px;
        width: 100%;
        height: 284px;
        padding: 16px;

        .MuiLinearProgress-bar {
            background: $main;
        }
    }

    .upload-docks {
        background: $greyWhite;
        border: 4px dashed $lightMain;
        border-radius: 8px;
        width: 100%;
        margin-left: 16px;
        height: 284px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (min-width: $xs) {
            margin-left: 0;
            margin-bottom: 15px;
        }

        @media (min-width: 1280px) {
            margin-left: 16px;
            margin-bottom: 0;
        }
    }

    .no-documents {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;

        p {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: $lightGunmetal;
        }
    }

    .filter-selector {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 16px;

        .select-option {
            width: 167px;
            height: 36px;
            padding: 0 16px;
            border: 1px solid $main;
            border-radius: 4px;
            @media (max-width: $md) {
                width: 100%;
            }

            select {
                width: 100%;
                height: 35px;
                outline: none;
                border: none;
                background: transparent;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #51566d;
            }
        }
    }

    .upload-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $darkText;
        margin: 26px 0 17px 0;
        @media (max-width: $md) {
            text-align: center;
        }
    }

    .upload-select {
        :hover .MuiOutlinedInput-notchedOutline {
            border-color: $main;
        }

        .MuiFilledInput-underline:after {
            border-color: $main;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: $main;
        }

        .Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $main;
        }

        .MuiOutlinedInput-root {
            height: 40px;
        }

        .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
            height: 40px;
        }

        .MuiFormControl-root {
            height: 40px;
        }

        .MuiSelect-select {
            width: 400px;
            max-width: 400px;
            @media (max-width: $md) {
                width: 180px;
            }
        }
        .mobile-upload {
            @media (max-width: $md) {
                display: flex;
                justify-content: center;
            }
        }
    }

    .custom-file-upload {
        margin-top: 24px;
        height: 30px;
    }

    input[type='file'] {
        display: none;
    }

    .upload {
        font-size: 24px;
        font-weight: 600;
        color: $main !important;
        margin-left: 8px;
        cursor: pointer;
    }

    .upload-err {
        font-size: 16px;
        font-weight: 600;
        color: $red !important;
        margin-left: 8px;
        @media (max-width: $md) {
            margin-left: 0;
        }
    }
}
