@import 'src/assets/styles/globalStyles';

.signup-form {
    @media (min-width: $xs) {
        width: 311px;
    }
    @media (min-width: $md) {
        width: 400px;
    }

    .footer-box {
        margin: 12px 0;
        text-align: center;

        .description {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $darkGunmetal;
        }

        .sign-up {
            margin-left: 5px;
            margin-right: 5px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $orange;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.enter-emile-title {
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $tableHeadText;
    @media (max-width: $md) {
        font-size: 14px;
    }
}
