.cancel-confirm {
    max-width: 426px;
    text-align: center;

    .chancel-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #324557;
    }

    .cancel-options {
        margin: 16px 0 32px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #51566d;
    }

    .actions {
        display: flex;
        justify-content: space-between;

        .no-button {
            width: 100%;
            height: 48px;
            background: #f5f7f9;
            border-radius: 4px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #41586d;
            border: none;
        }

        .yes-button {
            width: 100%;
            height: 48px;
            background: #0e9594;
            border-radius: 4px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #ffffff;
            margin-left: 16px;
            border: none;
        }
    }
}

.success-error-modal {
    max-width: 389px;
    height: auto;
    text-align: center;

    .title-style {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #2a374e;
        margin: 16px 0 8px;
    }

    .sub-title-style {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #2a374e;
    }

    .done-again-button {
        margin-top: 16px;
        width: 325px;
        height: 48px;
        background: #0e9594;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        border: none;
    }
}

.styled-restaurant-payment {
    .cancel-button-wrapper {
        padding: 0 17px;
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media (max-width: 768px) {
            margin-top: 24px;
        }
    }

    .cancel-button {
        margin-right: 16px;
        padding: 12px 81px;
        background-color: transparent;
        border: none;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #506c84;
        @media (max-width: 768px) {
            padding: 12px 36px;
        }
    }

    .billing-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: start;
        padding: 32px 42px;
        @media (max-width: $md) {
            padding: 32px 16px;
        }

        .g-title {
            font-size: 24px;
            font-family: Open Sans, sans-serif;
            font-weight: 700;
            color: #324557;
        }

        .active-plane {
            font-family: Open Sans, sans-serif;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #324557;
            @media (min-width: 320px) {
                margin: 24px 0 16px;
                //font-size: 18px;
                line-height: 28px;
            }
            @media (min-width: 769px) {
                margin: 24px 0;
                font-size: 24px;
                line-height: 42px;
            }
            @media (min-width: 1440px) {
                margin: 40px 0 24px;
            }
        }

        .cards-wrapper {
            width: 100%;
            height: auto;
            background-color: white;
            border: 1px solid #e9e9eb;
            border-radius: 8px;
            padding: 32px 48px;
            @media (min-width: 320px) {
                padding: 24px 16px;
            }
            @media (min-width: 769px) {
                padding: 24px;
            }
            @media (min-width: 1440px) {
                padding: 32px 48px;
            }
        }
    }
}
